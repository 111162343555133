import React, { useState } from 'react'
import './Fitness.css'
import emailjs from "emailjs-com";


import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import fitpic1 from '../Assests/fitpic1.png';
import fitpic2 from '../Assests/fitpic2.png';
import fitpic3 from '../Assests/fitpic3.png';
import fitpic4 from '../Assests/fitpic4.png';
import fitpic5 from '../Assests/fitpic5.png';
import fitpic6 from '../Assests/fitpic6.png';
import fitpic7 from '../Assests/fitpic7.png';
import fitpic8 from '../Assests/fitpic8.png';
import fitpic9 from '../Assests/fitpic9.png';
import fitpic10 from '../Assests/fitpic10.png';
import fitpic11 from '../Assests/fitpic11.png';
import fitpic12 from '../Assests/fitpic12.png';
import fitpic13 from '../Assests/fitpic13.png';
import fitpic14 from '../Assests/fitpic14.png';
import fitpic15 from '../Assests/fitpic15.png';
import fitpic16 from '../Assests/fitpic16.png';
import fitpic17 from '../Assests/fitpic17.png';
import fitpic18 from '../Assests/fitpic18.png';


const Fitness = () => {


  const fitnessfaqs = [
    { question: "Can the fitness games be customized for different training levels?", answer: "Yes, all our fitness games can be adjusted to match different fitness levels, from beginner to advanced." },
    { question: " Are these games suitable for group workouts?", answer: "Absolutely! Many of our games are designed for both individual and group participation, making them ideal for group training sessions." },
    { question: "How do I integrate these games into my fitness facility?", answer: "We provide full support, from installation to training, ensuring a seamless integration into your existing facility." },
  ]

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const[isScrolling,setIsScrolling]=useState(!0),[isDropdownOpen,setDropdownOpen]=useState({products:!1,industries:!1,support:!1}),[isHamburgerOpen,setHamburgerOpen]=useState(!1),[activeIndex,setActiveIndex]=useState(null),toggleHamburger=()=>{setHamburgerOpen(!isHamburgerOpen)},toggleDropdown=e=>{setDropdownOpen((s=>Object.keys(s).reduce(((t,n)=>(t[n]=n===e&&!s[n],t)),{})))},[isModalOpen,setIsModalOpen]=useState(!1),openModal=()=>{setIsModalOpen(!0)},closeModal=()=>{setIsModalOpen(!1)},handleClickOutside=e=>{"modal"===e.target.id&&setIsModalOpen(!1)};

        
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};
      




  return (
    <div>
{/* navbar section starts  */}
<>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" target='_blank' className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events"  target='_blank' className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness" className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family"  target='_blank' className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health"  target='_blank' className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location"  target='_blank' className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum"  target='_blank' className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical"  target='_blank' className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" />
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" target='_blank' className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events"  target='_blank' className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness" className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family"  target='_blank' className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health"  target='_blank' className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location"  target='_blank' className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum"  target='_blank' className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical"  target='_blank' className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
        </div>
      )}
    
    </>
{/* Navbar section ends  */}

{/* fitness1 section starts  */}
      <div className="fitness1">
      <div className="fitnessintro">
        <div className="fitnesshead1">
        <h3>Revolutionize Fitness with Interactive AR Games</h3>
        <h5>Enagage,Motivation and Train Like Never Before with Our Interactive Fitness and Sports Games.</h5>
        </div>
      </div>
      </div>
{/* fitness1 section ends  */}

{/* fitness2 section starts  */}
      <div className="fitness2">
        <div className="fitnessintro1">
            <div className="fitnesshead2">
                <h3>Why Choose Interactive Fitness and Sports Games?</h3>
                <hr className='fitnesshead2line' />
                <h5>Incorporating technology into fitness is the future of training and exercise. At InteractiveGames.io, we bring a new dimension to fitness with our Augmented Reality (AR) games, Interactive Wall Ball Throw Games, and Interactive Floor Experiences that make workouts more engaging, challenging, and enjoyable. Our games are designed to boost motivation, encourage competition, and provide a fun way to achieve fitness goals.
                </h5>
                <h5>Whether you’re a gym owner, a sports academy, a school, a corporate wellness program, or a builder looking to enhance fitness amenities, our interactive games offer a unique experience that transforms any fitness space.</h5>
            </div>
            <div className="fitnesscontent1">
                <a href=""><img src={fitpic2} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
                <div className="fitnesssubcontent1">
                    <h3>Got Questions About Gaming?</h3>
                    <h5>Our experts are ready to assist</h5>
                    <button  onClick={openModal} >Ask Your Gaming Query Now!</button>
                    <h3>Game Bundle</h3>
                    <a onClick={openModal}> &#11244; Explore Now &#11246; </a>
                    <h6>Pricing Avaiable Upon Request</h6>
                </div>
            </div>   
        </div>
      </div>
{/* fitness2 section ends  */}

{/* pop up contact form starts  */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
        
{/* pop up contactform ends  */}

{/* fitness3 section starts  */}
      <div className="fitness3">
        <div className="fitnessintro2">
          <div className="fitnesshead3">
          <h3>How We Elevate Fitness and Sports Training</h3>
          </div>
          <div className="fitnesscontent2">
            <div className="fitnesssubcontentitem1">
                  <a href=""><img src={fitpic3} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
                  <h4>AR Fitness Games</h4>
                  <h6>Our AR fitness games utilize body-tracking technology to monitor movements, making your workout more interactive and fun. These games are projected onto large screens, and participants control the game using their body movements, creating an engaging, full-body workout experience.
                  </h6>
            </div>
            <div className="fitnesssubcontentitem1">
                  <a href=""><img src={fitpic4} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
                  <h4>Interactive Wall Games for Fitness
                  </h4>
                  <h6>Our interactive wall games use ball-throw technology, allowing users to interact with projected games by throwing balls at the wall. Ideal for training coordination, agility, and reaction time, these games simulate sports like squash, tennis, and football, bringing an element of fun to traditional workouts.
                  </h6>
            </div>
            <div className="fitnesssubcontentitem1">
                  <a href=""><img src={fitpic5} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
                  <h4>Interactive Floor Fitness Experiences</h4>
                  <h6>Our Interactive Floor Fitness Experiences bring traditional functional training exercises, such as hopscotch and agility drills, to life using projectors and floor sensors. Instead of static floor markings, we create dynamic and interactive fitness challenges that respond to every step, jump, or movement, providing a more engaging and trackable workout experience.
                  </h6>
            </div>
            <div className="fitnesssubcontentitem1">
                  <a href=""><img src={fitpic6} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
                  <h4>Cycling Games</h4>
                  <h6>Our Cycling Games offer a completely different interactive experience by placing a big display in front of gym cycles. Cyclists can choose from a variety of scenic routes and virtual landscapes, creating an immersive environment that makes every ride exciting.
                  </h6>
            </div>
            
          </div>
         

        </div>
      </div>
{/* fitness3 section ends  */}

{/* fitness4 section starts  */}
      <div className="fitness4">
        <div className="fitnessintro3">
          <div className="fitnesshead4">
            <h3>Our-Ready-To-Use Fitness & Sports Games</h3>
            <h5>We have developed a range of games that combine fitness with interactive technology, suitable for gyms, sports academies, and fitness centers:</h5>
          </div>
          <div className="fitnesscontent3">
            <div className="fitsubcontentitem2">
               <a href=""><img src={fitpic7} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>AR Fitness Games</h5>
            </div>

            <div className="fitsubcontentitem2">
               <a href=""><img src={fitpic8} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Interactive Wall Games for Sports</h5>
            </div>

            <div className="fitsubcontentitem2">
               <a href=""><img src={fitpic9} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Interactive Cycling Games</h5>
            </div>
            

          </div>
        </div>
      </div>
{/* fitness4 section ends  */}

{/* fitness5 section starts  */}
      <div className="fitness5">
        <div className="fitnessintro4">
          <div className="fitnesshead5">
            <h3>Who Can Benefit from Our Interactive Fitness Games?</h3>
            <h5>Our interactive fitness and sports games are perfect for:</h5>
          </div>
          <div className="fitnesscontent4">
            <div className="fitsubcontentitem3">
               <a href=""><img src={fitpic10} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Gym Owners</h5>
            </div>

            <div className="fitsubcontentitem3">
               <a href=""><img src={fitpic11} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Sports Academies</h5>
            </div>

            <div className="fitsubcontentitem3">
               <a href=""><img src={fitpic12} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Schools & Colleges</h5>
            </div>

            <div className="fitsubcontentitem3">
               <a href=""><img src={fitpic13} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Corporate Wellness Programs</h5>
            </div>

            <div className="fitsubcontentitem3">
               <a href=""><img src={fitpic14} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Builders & Developers</h5>
            </div>
            

          </div>
        </div>
      </div>
{/* fitness5 section ends  */}

{/* fitness6 section starts  */}
      <div className="fitness6">
        <div className="fitnessintro5">
          <div className="fitnesshead6">
            <h3>Our Strengths: Why Partner with Us?</h3>
            
          </div>
          <div className="fitnesscontent5">
            <div className="fitsubcontentitem4">
               <a href=""><img src={fitpic15} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Advanced Technology</h5>
            </div>

            <div className="fitsubcontentitem4">
               <a href=""><img src={fitpic16} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Fully Customizable Games</h5>
            </div>

            <div className="fitsubcontentitem4">
               <a href=""><img src={fitpic17} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>Proven Experience</h5>
            </div>

            <div className="fitsubcontentitem4">
               <a href=""><img src={fitpic18} alt="Interactive fitness games,AR fitness solutions,Body tracking workout games,Virtual gym experiences,Fitness gamification,Sports training games,Group fitness interactive games,Fitness gaming technology,Interactive floor fitness,Sports gamification for gyms" /></a>
               <h5>End-to-End Support</h5>
            </div>
            

          </div>
        </div>
      </div>
{/* fitness6 section ends  */}

{/* fitness7 section starts  */}
      <div className="fitness7">
        <div className="fitnessintro6">
          <div className="fitnesshead7">
            <h3>Ready To Elevate Your Fitness Facility</h3>
            <h6>Partner with InteractiveGames.io to create an interactive fitness space that stands out. Our games are designed to enhance engagement, boost motivation, and provide a fun way to exercise. Whether you're looking for ready-to-use fitness games or custom solutions, we have everything you need to transform your gym or fitness center.</h6>
          </div>
          <div className="fitnesssubhead7">
            <h3>Get Started Today</h3>
            <h5>Mail: info@interactivegames.io</h5>
            <h5>Phone: +91 9876543210</h5>
            <button  onClick={openModal} >Request a Demo</button>
          </div>
        </div>
      </div>
{/* fitness7 section ends  */}

{/* fitness faq section starts  */}

      <div className="fitnessfaq-wrapper">
      <div className="fitnessfaq-container">
      <h1>Frequently Asked Questions</h1>
      {fitnessfaqs.map((fitnessfaq, index) => (
        <div key={index} className="fitnessfaq-item">
          <div className={`fitnessfaq-question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
            {fitnessfaq.question}
            <span className="fitnessarrow">{activeIndex === index ? '▲' : '▼'}</span>
          </div>
          {activeIndex === index && <div className="fitnessfaq-answer">{fitnessfaq.answer}</div>}
        </div>
      ))}
    </div>
    </div>
{/* fitness faq section ends  */}



            {/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}

      
    </div>
  )
}

export default Fitness
