import React, { useState } from 'react'
import './Commerical.css'
import emailjs from "emailjs-com";


import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import compic2 from '../Assests/compic2.png';
import compic3 from '../Assests/compic3.png';
import compic4 from '../Assests/compic4.png';
import compic5 from '../Assests/compic5.png';
import compic6 from '../Assests/compic6.png';
import compic7 from '../Assests/compic7.png';
import compic8 from '../Assests/compic8.png';
import compic9 from '../Assests/compic9.png';
import compic10 from '../Assests/compic10.png';
import compic11 from '../Assests/compic11.png';
import compic12 from '../Assests/compic12.png';
import compic13 from '../Assests/compic13.png';
import compic14 from '../Assests/compic14.png';
import compic15 from '../Assests/compic15.png';
import compic16 from '../Assests/compic16.png';
import compic17 from '../Assests/compic17.png';
import compic18 from '../Assests/compic18.png';
import compic19 from '../Assests/compic19.png';
import compic20 from '../Assests/compic20.png';
import compic21 from '../Assests/compic21.png';
import compic22 from '../Assests/compic22.png';
import compic23 from '../Assests/compic23.png';
import compic24 from '../Assests/compic24.png';
import compic25 from '../Assests/compic25.png';
import compic26 from '../Assests/compic26.png';
import compic27 from '../Assests/compic27.png';
import compic28 from '../Assests/compic28.png';
import compic29 from '../Assests/compic29.png';
import compic30 from '../Assests/compic30.png';
import compic31 from '../Assests/compic31.png';
import compic32 from '../Assests/compic32.png';
import compic33 from '../Assests/compic33.png';
import compic34 from '../Assests/compic34.png';
import compic35 from '../Assests/compic35.png';
import compic36 from '../Assests/compic36.png';

const Commerical = () => {

  
  const[isScrolling,setIsScrolling]=useState(!0),[isDropdownOpen,setDropdownOpen]=useState({products:!1,industries:!1,support:!1}),[isHamburgerOpen,setHamburgerOpen]=useState(!1),[activeIndex,setActiveIndex]=useState(null),toggleHamburger=()=>{setHamburgerOpen(!isHamburgerOpen)},toggleDropdown=e=>{setDropdownOpen((s=>Object.keys(s).reduce(((t,n)=>(t[n]=n===e&&!s[n],t)),{})))},[isModalOpen,setIsModalOpen]=useState(!1),openModal=()=>{setIsModalOpen(!0)},closeModal=()=>{setIsModalOpen(!1)},handleClickOutside=e=>{"modal"===e.target.id&&setIsModalOpen(!1)};

        
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};
      



  return (
    <div>

{/* navbar section starts  */}
<>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" target='_blank' className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events"  target='_blank' className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness"  target='_blank' className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family"  target='_blank' className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health"  target='_blank' className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location"  target='_blank' className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum"  target='_blank' className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical" className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" />
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" target='_blank' className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events"  target='_blank' className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness"  target='_blank' className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family"  target='_blank' className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health"  target='_blank' className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location"  target='_blank' className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum"  target='_blank' className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical" className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
        </div>
      )}
    
    </>
{/* navbar section ends  */}

{/* commerical1 section starts  */}
      <div className="commerical1">
      <div className="commericalintro1">
        <div className="commericalhead1">
        <h3>Maximize Your Revenue with Our Commercial Games Solutions</h3>
        <h5>Arcade Game Development, Import, and Technical Support for Monetizing Entertainment Experiences</h5>
        </div>
      </div>
      </div>
{/* commerical1 section ends  */}

{/* commerical2 section starts  */}
      <div className="commerical2">
        <div className="commericalintro2">
            <div className="commericalhead2">
                <h3>Why Choose Commercial Games for Your Business?</h3>
                <hr className='commericalhead2line' />              
                <h5>Arcade games are a proven way to generate revenue in entertainment venues, arcades, family fun centers, malls, and even restaurants. They attract a diverse audience, provide endless entertainment, and offer a lucrative opportunity for monetization. At InteractiveGames.io, we specialize in helping businesses succeed in the arcade gaming industry, offering complete support from game development and importing to technical assistance and customization.</h5>
                
            </div>
            <div className="commericalcontent1">
                <a href=""><img src={compic2} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <div className="commericalsubcontent1">
                    <h3>Got Questions About Gaming?</h3>
                    <h5>Our experts are ready to assist</h5>
                    <button  onClick={openModal} >Ask Your Gaming Query Now!</button>
                    <h3>Game Bundle</h3>
                    <a onClick={openModal}> &#11244; Explore Now &#11246; </a>
                    <h6>Pricing Avaiable Upon Request</h6>
                </div>
            </div>   
        </div>
      </div>
{/* commerical2 section ends  */}
{/* pop up contact form section starts  */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
{/* pop up contact form section ends         */}

{/* commerical3 section starts  */}
      <div className="commerical3">
        <div className="commericalintro3">
          <div className="commericalhead3">
            <h3>Our Commercial Game Services</h3>
          </div>
          <div className="commericalcontent2">
            <h3>1. Custom Arcade Game Development</h3>
            <h5>Do you have a vision for a new arcade game? Our team of expert developers and designers can turn your ideas into reality. We offer complete game development services, from conceptualization to design and programming.</h5>
            <div className="commericalcontent21">
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic3} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Tailored Game Design</h4>
                <h5>Create unique, engaging arcade games tailored to your venue or audience.</h5>
              </div>
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic4} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Monetization Integration</h4>
                <h5>We integrate coin, card, or digital payment systems, ensuring seamless revenue generation.</h5>
              </div>
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic5} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>High-Quality Graphics & Sound</h4>
                <h5>Our games feature high-definition graphics and immersive sound effects, providing an exceptional gaming experience.</h5>
              </div>
            </div> 
          </div>
          
          <div className="commericalcontent2">
            <h3>2. Importing & Sourcing Arcade Games</h3>
            <h5>If you’re looking to expand your arcade collection with proven, popular games, we offer importing and sourcing services to help you find the best arcade games from around the world.</h5>
            <div className="commericalcontent21">
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic6} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Extensive Network</h4>
                <h5> Access a wide range of arcade games through our global sourcing network, from classic retro games to the latest interactive experiences.</h5>
              </div>
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic7} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Customized Solutions</h4>
                <h5>We can customize imported games to match your brand, theme, or monetization preferences.</h5>
              </div>
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic8} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Hassle-Free Importing</h4>
                <h5>Our team handles the importing process, ensuring smooth delivery and setup.</h5>
              </div>
            </div> 
          </div>

          <div className="commericalcontent2">
            <h3>3. Technical Support & Maintenance</h3>
            <h5>Arcade games require ongoing technical support to ensure smooth operation and maximum uptime. Our experienced technicians provide comprehensive support for both software and hardware.</h5>
            <div className="commericalcontent21">
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic9} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Hardware Maintenance</h4>
                <h5>Regular servicing and repairs for arcade cabinets, controls, screens, and payment systems.</h5>
              </div>
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic10} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Software Updates</h4>
                <h5>We keep your games running smoothly with software updates, bug fixes, and new features.</h5>
              </div>
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic11} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>24/7 Support</h4>
                <h5>Our technical support team is available to assist you with any issues, ensuring your games are always ready for players.</h5>
              </div>
            </div> 
          </div>

          <div className="commericalcontent2">
            <h3>4. Game Development Support for Established Arcade Companies</h3>
            <h5>If you’re already in the arcade business but looking to expand or develop new games, we offer development support to help you bring new experiences to your customers.</h5>
            <div className="commericalcontent21">
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic12} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Collaborative Game Development</h4>
                <h5>Work with our team to create new game concepts, features, and mechanics that keep your arcade fresh and exciting.</h5>
              </div>
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic13} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Game Enhancement</h4>
                <h5> Update and improve your existing games with new levels, graphics, or features.</h5>
              </div>
              <div className="commericalsubcontentitem1">
                <a href=""><img src={compic14} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
                <h4>Hardware Integration</h4>
                <h5> We can help integrate your software with arcade hardware, ensuring a seamless experience.</h5>
              </div>
            </div> 
          </div>

        </div>
      </div>
{/* commerical3 section ends  */}

{/* commerical4 section starts  */}
      <div className="commerical4">
        <div className="commericalintro4">
          <div className="commericalhead4">
            <h3>Why Partner with Us for Your Commercial Games?</h3>
            
          </div>
          <div className="commericalcontent3">
            <div className="commericalsubcontentitem2">
              <a href=""><img src={compic15} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
              <h4> Expertise in Arcade Gaming</h4>
              <h5>Our team has years of experience in developing and supporting arcade games, giving us the knowledge and skills to help your business succeed. We understand the arcade gaming market and what players look for in a game, ensuring your offerings are always in demand.</h5>
            </div>

            <div className="commericalsubcontentitem2">
              <a href=""><img src={compic16} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
              <h4>Comprehensive Support</h4>
              <h5>We offer end-to-end support, from the initial concept to ongoing maintenance. Whether you need help developing a new game, importing existing ones, or maintaining your current collection, we’ve got you covered.</h5>
            </div>

            <div className="commericalsubcontentitem2">
              <a href=""><img src={compic17} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
              <h4>Customization & Branding</h4>
              <h5>We offer extensive customization options to match your brand, venue, or audience. From game graphics and sound to payment systems, we ensure every game fits seamlessly into your business model.</h5>
            </div>

            <div className="commericalsubcontentitem2">
              <a href=""><img src={compic18} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
              <h4>Revenue-Focused Solutions</h4>
              <h5>Our games and support services are designed with monetization in mind. We help you maximize revenue through payment integration, high-quality gaming experiences, and reliable support that keeps your arcade running smoothly.</h5>
            </div>

          </div>
        </div>
      </div>
{/* commerical4 section ends  */}

{/* commerical5 section starts  */}
      <div className="commerical5">
        <div className="commericalintro5">
          <div className="commericalhead5">
            <h3>Types of Arcade Games We Offer</h3>
            <h5>We provide a wide variety of arcade games suitable for all audiences, ensuring your business has the right mix to attract players and maximize revenue:</h5>
          </div>
          <div className="commericalcontent4">
            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic19} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Classic Arcade Games</h5>
            </div>

            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic20} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Interactive Motion Games</h5>
            </div>

            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic21} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Sports & Racing Games</h5>
            </div>

            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic22} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Puzzle & Strategy Games</h5>
            </div>

            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic23} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Augmented Reality (AR) Games</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="commerical5">
        <div className="commericalintro5">
          <div className="commericalhead5">
            <h3>Customization & Monetization Options</h3>
            <h5>We understand that every business has different needs, so we offer a range of customization and monetization options:</h5>
          </div>
          <div className="commericalcontent4">
            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic24} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Custom Branding</h5>
            </div>

            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic25} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Payment Integration</h5>
            </div>

            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic26} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Gameplay Adjustments</h5>
            </div>

            <div className="commericalsubcontentitem3">
               <a href=""><img src={compic27} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Marketing Integration</h5>
            </div>
          </div>
        </div>
      </div>

{/* commerical5 section ends  */}

{/* commerical6 section starts  */}
      <div className="commerical6">
        <div className="commericalintro6">
          <div className="commericalhead6">
            <h3>Our Process: How We Help You Succeed in Arcade Gaming</h3>
          </div>
          <div className="commericalcontent5">
            <div className="commericalsubcontentitem4">
               <a href=""><img src={compic28} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Consultation & Planning</h5>
            </div>

            <div className="commericalsubcontentitem4">
               <a href=""><img src={compic29} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Design & Development</h5>
            </div>

            <div className="commericalsubcontentitem4">
               <a href=""><img src={compic30} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Installation & Integration</h5>
            </div>

            <div className="commericalsubcontentitem4">
               <a href=""><img src={compic31} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Ongoing Support & Maintenance</h5>
            </div>
          </div>
        </div>
      </div>
{/* commerical6 section ends  */}

{/* commerical7 section starts  */}
      <div className="commerical7">
        <div className="commericalintro7">
          <div className="commericalhead7">
            <h3>Industries We Serve</h3>
            <h5>Our commercial games solutions are ideal for a variety of businesses looking to monetize arcade experiences:</h5>
          </div>
          <div className="commericalcontent6">
            <div className="commericalsubcontentitem5">
               <a href=""><img src={compic32} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Arcade Centers & Family Entertainment Centers</h5>
            </div>

            <div className="commericalsubcontentitem5">
               <a href=""><img src={compic33} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Restaurants & Cafés</h5>
            </div>

            <div className="commericalsubcontentitem5">
               <a href=""><img src={compic34} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Malls & Shopping Centers</h5>
            </div>

            <div className="commericalsubcontentitem5">
               <a href=""><img src={compic35} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Amusement Parks & Resorts</h5>
            </div>

            <div className="commericalsubcontentitem5">
               <a href=""><img src={compic36} alt="Arcade game development,Custom arcade games,Arcade game importing,Commercial game solutions,Monetizing arcade games,Interactive arcade experiences,Coin-operated game solutions,Arcade game technical support,Revenue-generating arcade games,Custom branded arcade games" /></a>
               <h5>Corporate & Event Spaces</h5>
            </div>

          </div>
        </div>
      </div>
{/* commerical7 section ends  */}

{/* commerical8 section starts  */}
      <div className="commerical8">
        <div className="commericalintro8">
          <div className="commericalhead8">
            <h3>Ready to Boost Your Revenue with Commercial Games?</h3>
            <h6>Whether you're looking to develop custom arcade games, import the latest hits, or need technical support for your existing games, InteractiveGames.io is here to help. Our team has the expertise, creativity, and technical know-how to ensure your arcade games are a profitable addition to your business.</h6>
          </div>
          <div className="commericalsubhead8">
            <h3>Get Started Today</h3>
            <h5>Mail: info@interactivegames.io</h5>
            <h5>Phone: +91 9876543210</h5>
            <button  onClick={openModal} >Request a Demo</button>
          </div>
        </div>
      </div>
{/* commerical8 section ends  */}

            {/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}


    </div>
  )
}

export default Commerical