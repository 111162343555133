import React, { useState } from 'react'
import './Events.css'
import emailjs from "emailjs-com";


import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import eventspic2 from '../Assests/eventspic2.png';
import eventspic3 from '../Assests/eventspic3.png';
import eventspic4 from '../Assests/eventspic4.png';
import eventspic5 from '../Assests/eventspic5.png';
import eventspic6 from '../Assests/eventspic6.png';
import eventspic7 from '../Assests/eventspic7.png';
import eventspic8 from '../Assests/eventspic8.png';
import eventspic9 from '../Assests/eventspic9.png';
import eventspic10 from '../Assests/eventspic10.png';
import eventspic11 from '../Assests/eventspic11.png';
import eventspic12 from '../Assests/eventspic12.png';
import eventspic13 from '../Assests/eventspic13.png';
import eventspic14 from '../Assests/eventspic14.png';
import eventspic15 from '../Assests/eventspic15.png';
import eventspic16 from '../Assests/eventspic16.png';
import eventspic17 from '../Assests/eventspic17.png';
import eventspic18 from '../Assests/eventspic18.png';
import eventspic19 from '../Assests/eventspic19.png';
import eventspic20 from '../Assests/eventspic20.png';
import eventspic21 from '../Assests/eventspic21.png';
import eventspic22 from '../Assests/eventspic22.png';
import eventspic23 from '../Assests/eventspic23.png';
import eventspic24 from '../Assests/eventspic24.png';
import eventspic25 from '../Assests/eventspic25.png';

const Events = () => {

  const[isScrolling,setIsScrolling]=useState(!0),[isDropdownOpen,setDropdownOpen]=useState({products:!1,industries:!1,support:!1}),[isHamburgerOpen,setHamburgerOpen]=useState(!1),[activeIndex,setActiveIndex]=useState(null),toggleHamburger=()=>{setHamburgerOpen(!isHamburgerOpen)},toggleDropdown=e=>{setDropdownOpen((s=>Object.keys(s).reduce(((t,n)=>(t[n]=n===e&&!s[n],t)),{})))},[isModalOpen,setIsModalOpen]=useState(!1),openModal=()=>{setIsModalOpen(!0)},closeModal=()=>{setIsModalOpen(!1)},handleClickOutside=e=>{"modal"===e.target.id&&setIsModalOpen(!1)};
        
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};
      




  return (
    <div>

{/* navbar section starts  */}
<>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" target='_blank' className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events" className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness"  target='_blank' className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family"  target='_blank' className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health"  target='_blank' className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location"  target='_blank' className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum"  target='_blank' className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical"  target='_blank' className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" />
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" target='_blank' className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events" className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness"  target='_blank' className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family"  target='_blank' className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health"  target='_blank' className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location"  target='_blank' className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum"  target='_blank' className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical"  target='_blank' className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
        </div>
      )}
    
    </>
{/* navbar section ends  */}

{/* events1 section starts  */}
      <div className="events1">
      <div className="eventsintro1">
        <div className="eventshead1">
        <h3>Make Your Event Unforgettable with Interactive Event Games</h3>
        <h5> Engaging, Immersive, and Customizable Experiences for Every Type of Event</h5>
        </div>
      </div>
      </div>
{/* events1 section ends  */}

{/* events2 section starts  */}
      <div className="events2">
        <div className="eventsintro2">
            <div className="eventshead2">
                <h3>Why Choose Interactive Event Games for Your Next Event?</h3>
                <hr className='eventshead2line' />
                <h5>In today’s dynamic event landscape, attendees crave more than just entertainment—they want experiences that are engaging, interactive, and memorable. At InteractiveGames.io, we specialize in creating immersive event games that bring excitement, interaction, and innovation to any occasion. Whether you're hosting a corporate event, festival, wedding, birthday party, or brand activation, our interactive games transform ordinary events into extraordinary experiences.
                </h5>
                
            </div>
            <div className="eventscontent1">
                <a href=""><img src={eventspic2} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <div className="eventssubcontent1">
                    <h3>Got Questions About Gaming?</h3>
                    <h5>Our experts are ready to assist</h5>
                    <button  onClick={openModal} >Ask Your Gaming Query Now!</button>
                    <h3>Game Bundle</h3>
                    <a onClick={openModal}> &#11244; Explore Now &#11246; </a>
                    <h6>Pricing Avaiable Upon Request</h6>
                </div>
            </div>   
        </div>
      </div>
{/* events2 section ends  */}
{/* pop up contact form starts  */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
{/* pop up contact form ends         */}

{/* events3 section starts  */}
      <div className="events3">
        <div className="eventsintro3">
          <div className="eventshead3">
            <h3>Types of Events We Cater To</h3>
          </div>
          <div className="eventscontent2">
            <div className="eventssubcontentitem1">
               <a href=""><img src={eventspic3} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
               <h5>Corporate Events & Team Building</h5>
            </div>

            <div className="eventssubcontentitem1">
               <a href=""><img src={eventspic4} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
               <h5>Weddings & Private Parties</h5>
            </div>

            <div className="eventssubcontentitem1">
               <a href=""><img src={eventspic5} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
               <h5>Festivals & Public Events</h5>
            </div>

            <div className="eventssubcontentitem1">
               <a href=""><img src={eventspic6} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
               <h5>Product Launches & Brand Brand Activations</h5>
            </div>

            <div className="eventssubcontentitem1">
               <a href=""><img src={eventspic7} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
               <h5>Kid's Parties & Family Entertainment</h5>
            </div>
          </div>
        </div>
      </div>
{/* events3 section ends  */}

{/* events4 section starts  */}
      <div className="events4">
        <div className="eventsintro4">
          <div className="eventshead4">
            <h3>Our Capabilities: Bringing Your Event to Life</h3>
          </div>
          <div className="eventscontent3">
            <h3>1. Advanced Technologies</h3>
            <h5>Our team specializes in utilizing cutting-edge technologies to create interactive experiences that keep guests engaged and entertained:</h5>
            <div className="eventscontent31">
              <div className="eventssubcontentitem2">
                <a href=""><img src={eventspic8} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <h4>Body Tracking</h4>
              </div>
              <div className="eventssubcontentitem2">
                <a href=""><img src={eventspic9} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <h4>Augmented Reality(AR)</h4>
              </div>
              <div className="eventssubcontentitem2">
                <a href=""><img src={eventspic10} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <h4>Projection Mapping</h4>
              </div>
              <div className="eventssubcontentitem2">
                <a href=""><img src={eventspic11} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <h4>Touchless Interaction</h4>
              </div>

            </div>
            
          </div>
          <div className="eventscontent3">
            <h3>2. Customized Game Experiences</h3>
            <h5>No two events are the same, and that’s why we offer fully customized game experiences tailored to your theme, brand, or objectives:</h5>
            <div className="eventscontent31">
              <div className="eventssubcontentitem2">
                <a href=""><img src={eventspic12} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <h4>Themed Content</h4>
              </div>
              <div className="eventssubcontentitem2">
                <a href=""><img src={eventspic13} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <h4>Branded Integration</h4>
              </div>
              <div className="eventssubcontentitem2">
                <a href=""><img src={eventspic14} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <h4>Multi-Player & Group Games</h4>
              </div>
              <div className="eventssubcontentitem2">
                <a href=""><img src={eventspic15} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
                <h4>Language & Accessibility</h4>
              </div>

            </div>
            
          </div>
        </div>
      </div>
{/* events4 section ends  */}

{/* events6 section starts  */}
      <div className="events6">
        <div className="eventsintro6">
          <div className="eventshead6">
            <h3>Customization & Branding</h3>
            <h5>We understand that every event is unique, and our team works closely with you to ensure our games reflect your event’s theme, goals, and branding:</h5>
          </div>
          <div className="eventscontent5">
            <div className="eventssubcontentitem4">
              <a href=""><img src={eventspic16} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Brand Integartion</h4>
              <h5> Add your logo, colors, and brand messages to our games, ensuring every aspect aligns with your event's identity.</h5>
            </div>

            <div className="eventssubcontentitem4">
              <a href=""><img src={eventspic17} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Themed Experiences</h4>
              <h5> We can tailor game content to fit any theme, from corporate team-building to children’s parties, ensuring a seamless experience for your guests.
              </h5>
            </div>

            <div className="eventssubcontentitem4">
              <a href=""><img src={eventspic18} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Custom Game Development</h4>
              <h5> If you have a unique idea or concept, our team of designers and developers can bring it to life, creating a bespoke game that matches your vision.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="events6">
        <div className="eventsintro6">
          <div className="eventshead6">
            <h3>Our Team’s Expertise
            </h3>
            <h5>Our experienced team consists of game designers, developers, technicians, and event specialists who are passionate about creating unforgettable experiences. We bring:</h5>
          </div>
          <div className="eventscontent5">
            <div className="eventssubcontentitem4">
              <a href=""><img src={eventspic19} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Technical Expertise</h4>
              <h5> Years of experience in developing and implementing interactive technologies for a wide range of events.</h5>
            </div>

            <div className="eventssubcontentitem4">
              <a href=""><img src={eventspic20} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Creative Vision</h4>
              <h5>We pride ourselves on our creativity, ensuring every game we create is engaging, fun, and perfectly tailored to your event.
              </h5>
            </div>

            <div className="eventssubcontentitem4">
              <a href=""><img src={eventspic21} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Seamless Setup & Support</h4>
              <h5> From initial planning to on-site installation and support, our team ensures your event games run smoothly, so you can focus on enjoying the occasion.
              </h5>
            </div>
          </div>
        </div>
      </div>
{/* events6 section ends  */}

{/* events7 section starts  */}
      <div className="events7">
        <div className="eventsintro7">
          <div className="eventshead7">
            <h3>Why Choose Us for Your Event Games?</h3>
            
          </div>
          <div className="eventscontent6">
            <div className="eventssubcontentitem5">
              <a href=""><img src={eventspic22} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Innovative Technologies</h4>
              <h5>We use the latest in AR, body tracking, projection mapping, and touchless technology to create truly immersive experiences.</h5>
            </div>

            <div className="eventssubcontentitem5">
              <a href=""><img src={eventspic23} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Customized Solutions</h4>
              <h5>We tailor our games to match your event’s theme, brand, and goals, ensuring a unique and memorable experience.</h5>
            </div>

            <div className="eventssubcontentitem5">
              <a href=""><img src={eventspic24} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Engaging & Interactive</h4>
              <h5>Our games are designed to engage guests of all ages, making your event stand out from the rest.</h5>
            </div>

            <div className="eventssubcontentitem5">
              <a href=""><img src={eventspic25} alt="Interactive games for events,AR games for corporate events,Body tracking event games,Projection mapping for events,Custom event game experiences,Touchless games for events,Immersive event technology,Interactive floor games for events,Augmented reality games for parties,Branded games for events,interactive treasure hunt,interactive virtual games,interactive team building games,interactive activities for corporate events" /></a>
              <h4>Full-Service Support</h4>
              <h5>From concept to execution, we provide end-to-end support, ensuring your event games are set up, managed, and maintained seamlessly.</h5>
            </div>
          </div>
        </div>
      </div>
{/* events7 section ends  */}

{/* events8 section starts  */}
      <div className="events8">
        <div className="eventsintro8">
          <div className="eventshead8">
            <h3>Ready to Elevate Your Event with Interactive Games?</h3>
            <h6>Let’s make your next event unforgettable with InteractiveGames.io. Whether you’re hosting a corporate gathering, a festival, or a private celebration, our interactive games are designed to engage, entertain, and leave a lasting impression.</h6>
          </div>
          <div className="eventssubhead8">
            <h3>Get Started Today</h3>
            <h5>Mail: info@interactivegames.io</h5>
            <h5>Phone: +91 9876543210</h5>
            <button  onClick={openModal} >Request a Demo</button>
          </div>
        </div>
      </div>
{/* events8 section ends  */}



            {/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}

      
    </div>
  )
}

export default Events
