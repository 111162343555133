import React, { useState } from 'react'
import './Location.css'
import emailjs from "emailjs-com";



import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import locpic2 from '../Assests/locpic2.png';
import locpic3 from '../Assests/locpic3.png';
import locpic4 from '../Assests/locpic4.png';
import locpic5 from '../Assests/locpic5.png';
import locpic6 from '../Assests/locpic6.png';
import locpic7 from '../Assests/locpic7.png';
import locpic8 from '../Assests/locpic8.png';
import locpic9 from '../Assests/locpic9.png';
import locpic10 from '../Assests/locpic10.png';
import locpic11 from '../Assests/locpic11.png';
import locpic12 from '../Assests/locpic12.png';
import locpic13 from '../Assests/locpic13.png';
import locpic14 from '../Assests/locpic14.png';
import locpic15 from '../Assests/locpic15.png';
import locpic16 from '../Assests/locpic16.png';
import locpic17 from '../Assests/locpic17.png';
import locpic18 from '../Assests/locpic18.png';
import locpic19 from '../Assests/locpic19.png';
import locpic20 from '../Assests/locpic20.png';
import locpic21 from '../Assests/locpic21.png';
import locpic22 from '../Assests/locpic22.png';
import locpic23 from '../Assests/locpic23.png';
import locpic24 from '../Assests/locpic24.png';
import locpic25 from '../Assests/locpic25.png';
import locpic26 from '../Assests/locpic26.png';
import locpic27 from '../Assests/locpic27.png';
import locpic28 from '../Assests/locpic28.png';
import locpic29 from '../Assests/locpic29.png';
import locpic30 from '../Assests/locpic30.png';
import locpic31 from '../Assests/locpic31.png';
import locpic32 from '../Assests/locpic32.png';


const Location = () => {

  const[isScrolling,setIsScrolling]=useState(!0),[isDropdownOpen,setDropdownOpen]=useState({products:!1,industries:!1,support:!1}),[isHamburgerOpen,setHamburgerOpen]=useState(!1),[activeIndex,setActiveIndex]=useState(null),toggleHamburger=()=>{setHamburgerOpen(!isHamburgerOpen)},toggleDropdown=e=>{setDropdownOpen((s=>Object.keys(s).reduce(((t,n)=>(t[n]=n===e&&!s[n],t)),{})))},[isModalOpen,setIsModalOpen]=useState(!1),openModal=()=>{setIsModalOpen(!0)},closeModal=()=>{setIsModalOpen(!1)},handleClickOutside=e=>{"modal"===e.target.id&&setIsModalOpen(!1)};

        
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};
      




  return (
    <div>
{/* navbar section starts  */}
<>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" target='_blank' className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events"  target='_blank' className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness"  target='_blank' className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family"  target='_blank' className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health"  target='_blank' className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location" className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum"  target='_blank' className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical"  target='_blank' className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" />
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" target='_blank' className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events"  target='_blank' className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness"  target='_blank' className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family"  target='_blank' className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health"  target='_blank' className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location" className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum"  target='_blank' className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical"  target='_blank' className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
        </div>
      )}
    
    </>
{/* navbar section ends  */}


{/* location1 section starts */}
<div className="location1">
      <div className="locationintro1">
        <div className="locationhead1">
        <h3>Transforming Location-Based Entertainment with Cutting-Edge Technology</h3>
        <h5>Elevate Your LBE Experience with Innovative Concepts, Immersive Technologies, and Tailored Solutions</h5>
        </div>
      </div>
      </div>
{/* location1 section ends  */}

{/* location2 section starts  */}
      <div className="location2">
        <div className="locationintro2">
            <div className="locationhead2">
                <h3>What is Location-Based Entertainment (LBE)?</h3>
                <hr className='locationhead2line' />              
                <h5>Location-Based Entertainment (LBE) refers to a variety of entertainment experiences that take place at physical locations, offering guests a chance to engage with immersive and interactive experiences outside their homes. From theme parks and arcades to escape rooms and immersive art installations, LBEs are designed to create unforgettable moments that captivate and engage audiences of all ages.</h5>
                <h5>At InteractiveGames.io, we specialize in bringing the latest technology to LBE venues, offering unique, customizable, and innovative solutions that transform traditional spaces into immersive experiences. Whether you’re looking to create a high-tech theme park, an interactive arcade, or a multisensory digital installation, we have the expertise to make it happen.</h5>
            </div>
            <div className="locationcontent1">
                <a href=""><img src={locpic2} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <div className="locationsubcontent1">
                    <h3>Got Questions About Gaming?</h3>
                    <h5>Our experts are ready to assist</h5>
                    <button  onClick={openModal} >Ask Your Gaming Query Now!</button>
                    <h3>Game Bundle</h3>
                    <a onClick={openModal}> &#11244; Explore Now &#11246; </a>
                    <h6>Pricing Avaiable Upon Request</h6>
                </div>
            </div>   
        </div>
      </div>
{/* location2 section ends  */}

{/* pop up contactform starts  */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
{/* pop up contactform ends  */}       

{/* location3 section starts */}
      <div className="location3">
        <div className="locationintro3">
          <div className="locationhead3">
            <h3>Our Capabilities: What We Bring to LBE</h3>
          </div>
          <div className="locationcontent2">
            <h3>1. Immersive Technologies & Experiences</h3>
            <h5>We leverage advanced technologies to create interactive and immersive environments, making your LBE venue more engaging and memorable:</h5>
            <div className="locationcontent21">
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic3} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Augmented Reality (AR) & Virtual Reality (VR)</h4>
                <h5>Transport guests into different worlds with AR and VR experiences. From virtual roller coasters to AR treasure hunts, we create experiences that blur the lines between reality and the digital world.</h5>
              </div>
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic4} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Projection Mapping</h4>
                <h5>Transform any surface—walls, floors, or objects—into dynamic displays with projection mapping. Create storytelling experiences, interactive art installations, or visually stunning attractions that change the environment in real-time.</h5>
              </div>
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic5} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Interactive Floors & Walls</h4>
                <h5>Bring your space to life with interactive floors and walls that respond to touch, movement, and sound. Ideal for arcades, family entertainment centers, and escape rooms, these installations engage guests with hands-on experiences.
                </h5>
              </div>
            </div> 
          </div>
          
          <div className="locationcontent2">
            <h3>2. Customizable Game Experiences</h3>
            <h5>Our team can design and develop customized game experiences that fit your venue, brand, or theme:</h5>
            <div className="locationcontent21">
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic6} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Themed Escape Rooms</h4>
                <h5>Create fully immersive escape rooms with puzzles that use AR, VR, and interactive technology to challenge players in exciting ways. Each escape room is designed to provide a one-of-a-kind experience tailored to your storyline.</h5>
              </div>
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic7} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Interactive Ball Games</h4>
                <h5>We offer ball-throw games with customizable themes, from space battles to underwater adventures, turning any space into an action-packed arena.
                </h5>
              </div>
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic8} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Motion-Tracking Sports Games</h4>
                <h5> Incorporate sports-based games that use motion tracking to engage players in activities like virtual basketball, tennis, or bowling. These games can be projected onto walls or played using AR goggles for a more immersive experience.
                </h5>
              </div>
            </div> 
          </div>

          <div className="locationcontent2">
            <h3>3. Multisensory Digital Installations</h3>
            <h5>Engage all the senses with multisensory digital installations that combine sight, sound, touch, and even scent to create unforgettable experiences:</h5>
            <div className="locationcontent21">
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic9} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Holographic Projections</h4>
                <h5>Bring digital content to life with 3D holographic projections. Imagine guests interacting with life-sized characters, virtual guides, or digital art in a way that feels real and tangible.</h5>
              </div>
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic10} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Fog Screens & Water Projections</h4>
                <h5>Project visuals onto fog or water to create captivating, ethereal displays that float in mid-air. These installations are perfect for water parks, immersive art exhibits, and outdoor entertainment centers.</h5>
              </div>
              <div className="locationsubcontentitem1">
                <a href=""><img src={locpic11} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>360-Degree VR Domes</h4>
                <h5>Immerse guests in a full-dome experience, where they can look up, down, and around to explore virtual environments. Ideal for theme parks, educational installations, and interactive storytelling.</h5>
              </div>
            </div> 
          </div>

        </div>
      </div>
{/* location3 section ends */}

{/* location4 section starts */}
      <div className="location4">
        <div className="locationintro4">
          <div className="locationhead4">
            <h3>Our LBE Solutions Across Different Venues</h3>
          </div>
          <div className="locationcontent3">
            <h3>1. Theme Parks & Amusement Centers</h3>
            <div className="locationcontent31">
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic12} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>AR Treasure Hunts</h4>
              </div>
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic13} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>VR Roller Coasters</h4>
              </div>
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic14} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Interactive Storytelling Zones</h4>
              </div> 
          </div> 
          </div>

          <div className="locationcontent3">
            <h3>2. Arcades & Gaming Centers</h3>
            <div className="locationcontent31">
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic15} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Body-Tracking Games</h4>
              </div>
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic16} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Mixed Reality Escape Games</h4>
              </div>
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic17} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Touchless Arcade Machines</h4>
              </div>
            </div> 
          </div>

          <div className="locationcontent3">
            <h3>3. Family Entertainment Centers</h3>
            <div className="locationcontent31">
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic18} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Interactive Climbing Walls</h4>
              </div>
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic19} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Digital Hopscotch & Floor Games</h4>
              </div>
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic20} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Augmented Reality Play Zones</h4>
              </div>
            </div> 
          </div>

          <div className="locationcontent3">
            <h3>4. Immersive Art Installations</h3>
            <div className="locationcontent31">
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic21} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Projection Mapping Art</h4>
              </div>
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic22} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Multisensory Rooms</h4>
              </div>
            </div> 
          </div>

          <div className="locationcontent3">
            <h3>5. Corporate Events & Trade Shows</h3>
            <div className="locationcontent31">
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic23} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Interactive Product Displays</h4>
              </div>
              <div className="locationsubcontentitem2">
                <a href=""><img src={locpic24} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
                <h4>Virtual Reality Experiences</h4>
              </div>
            </div> 
          </div>

        </div>
      </div>
{/* location4 section ends */}

{/* location5 section starts */}
      <div className="location5">
        <div className="locationintro5">
          <div className="locationhead5">
            <h3>Why Choose Us for Your LBE Projects?</h3>
            
          </div>
          <div className="locationcontent4">
            <div className="locationsubcontentitem3">
              <a href=""><img src={locpic25} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
              <h4>Expertise in Immersive Technology</h4>
              <h5>Our team has extensive experience in designing and implementing interactive experiences using the latest technologies, from AR and VR to projection mapping.</h5>
            </div>

            <div className="locationsubcontentitem3">
              <a href=""><img src={locpic26} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
              <h4>Custom Solutions for Every Venue</h4>
              <h5>We understand that every LBE venue is unique. That’s why we offer fully customizable solutions tailored to your space, brand, and audience.</h5>
            </div>

            <div className="locationsubcontentitem3">
              <a href=""><img src={locpic27} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
              <h4>End-to-End Support</h4>
              <h5>From concept development to installation and technical support, we provide comprehensive solutions to ensure your LBE venue runs smoothly.</h5>
            </div>

            <div className="locationsubcontentitem3">
              <a href=""><img src={locpic28} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
              <h4>Cutting-Edge Sourcing Capabilities</h4>
              <h5>We source the best technology from around the world, ensuring your LBE venue has access to the latest and most innovative solutions available.</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="location5">
        <div className="locationintro5">
          <div className="locationhead5">
            <h3>Our Process: Bringing Your LBE Vision to Life</h3>
            
          </div>
          <div className="locationcontent4">
            <div className="locationsubcontentitem3">
              <a href=""><img src={locpic29} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
              <h4>Consultation & Concept Development</h4>
              <h5>We start by understanding your vision, goals, and target audience to create a concept that aligns with your objectives.</h5>
            </div>

            <div className="locationsubcontentitem3">
              <a href=""><img src={locpic30} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
              <h4>Design & Development</h4>
              <h5>Our team designs and develops the technology, content, and interactive elements needed to bring your LBE venue to life.</h5>
            </div>

            <div className="locationsubcontentitem3">
              <a href=""><img src={locpic31} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
              <h4>Installation & Integration</h4>
              <h5>We handle the setup and integration of all technology, ensuring everything works seamlessly.</h5>
            </div>

            <div className="locationsubcontentitem3">
              <a href=""><img src={locpic32} alt="LBE technology solutions,AR and VR for location-based entertainment,Digital experiences for LBEs,Touchless technology for LBE,Immersive gaming for LBE venues,Interactive LBE games,Projection mapping for LBE venues,Innovative LBE experiences,Body tracking games for LBE,Customized LBE solutions" /></a>
              <h4>Support & Maintenance</h4>
              <h5>We provide ongoing support and maintenance to ensure your LBE experience remains engaging and operates smoothly.</h5>
            </div>
          </div>
        </div>
      </div>
{/* location5 section ends */}

{/* location6 section starts */}
      <div className="location6">
        <div className="locationintro6">
          <div className="locationhead6">
            <h3>Get Started with Location-Based Entertainment Today!</h3>
            <h6>Ready to transform your venue into an unforgettable, interactive destination? Let InteractiveGames.io help you create an LBE experience that will captivate and engage guests like never before. From concept to execution, we have the expertise, technology, and creativity to bring your vision to life.</h6>
          </div>
          <div className="locationsubhead6">
            <h3>Get Started Today</h3>
            <h5>Mail: info@interactivegames.io</h5>
            <h5>Phone: +91 9876543210</h5>
            <button  onClick={openModal} >Request a Demo</button>
          </div>
        </div>
      </div>
{/* location6 section ends */}

{/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}

      
    </div>
  )
}

export default Location
