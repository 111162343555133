import React, {useState} from 'react'
import './Landing.css'
import emailjs from "emailjs-com";

import homeicon from '../Assests/home_icon.svg';
import banner2img from '../Assests/banner2_img.png';
import projectimg from '../Assests/projectorimg.png';
import space from '../Assests/space.mp4';
import neww from '../Assests/neww.svg';
import warranty from '../Assests/warranty.svg';
import support from '../Assests/support.svg';
import customize from '../Assests/customize.svg';
import delivery from '../Assests/delivery.svg';
import use1 from '../Assests/use1.jpg';
import use2 from '../Assests/use2.jpg';
import use3 from '../Assests/use3.jpg';
import use4 from '../Assests/use4.jpg';
import use5 from '../Assests/use5.jpg';
import use6 from '../Assests/use6.jpg';
import landingvideo from '../Assests/Landing-video.mp4';
import sarath from '../Assests/sarath.png';
import landing_photo from '../Assests/landing_photo.png';



const Landing = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    { question: "How long does delivery take?", answer: "Delivery typically takes 7 days." },
    { question: "Are delivery charges included?", answer: "Delivery charges are extra and not included in the product price." },
    { question: "What kind of warranty do you offer?", answer: "We offer a 1-year warranty for the camera and PC. The projector comes with a brand-specific warranty." },
    { question: "What are the GST rates for the products?", answer: "The GST rate for the projector is 28%, while other items are taxed at 18%." },
    { question: "Can I change the games later?", answer: "We will Addon upto 20 games in a year.But if you want a customized games charges are applicable." },
    { question: "Do I need to pay after one year?", answer: "No." },
    { question: "Minimum space required to install projectors?", answer: "8'ft × 6'ft minimum for floor space or wall space. "},
    { question: " How easy is it to set  up the interactive projector?" , answer: "The setup process is straightforward, and we provide installation video and online support to assist you through every step."},
    { question: " Do I need balls and net?" , answer: " It's optional for interactive wall games ,it is customized as per site requirement."}
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClickOutside = (event) => {
    if (event.target.id === 'modal') {
      closeModal();
    }
  };


  
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};





  return (
    <div>
      {/* Navbar section starts  */}
      <div className="landing-navbar-container">
         <div className="landing-navbar">
            <div className="left">
               <h1 className='title1'>INTERACTIVE GAMES</h1>
                <p className='subtitle1'>POWERED BY ESHARK</p>
            </div>

          <div className="right">
            <a href="/" target='_blank'><img className='landingnavimg' src={homeicon} alt="home-icon" /></a>
          </div>
        </div>
      </div>
      
{/* navbar section ends  */}

    {/* video section starts  */}
      <div class="banner">
    <div class="heading">
        <p class="title2"><span>Increase footfall</span> in your store.</p>
        <p class="subtitle2">It takes <span>just 30 minutes to install</span> by yourself.</p>
    </div>
    <div class="video-wrapper">
        <video autoPlay loop muted  playsinline class="bannerphoto">
            <source src={landingvideo} type="video/mp4" />
            Your browser does not support the video tag. 
        </video>
    </div>
</div>
{/* video section ends  */}

{/* banner2 section starts   */}
        <div className="banner2">
            <div className="heading2">
                <p className='title3'>Convert any <span>wall into an interactive game</span></p>
                <p className='subtitle3'>with our exciting ball throw setup </p>
            </div>
            <div className="photo2">
                <img className='bannerphoto2' src={banner2img} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" />
                <img className='bannerphoto2' src={landing_photo} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" />
            </div>
        </div>
{/* banner2 section ends  */}

{/* projector section starts  */}
        <div className="projector">
            <div className="heading3"> 
                <p className='title4'>The All-in-one Interactive Projector</p>
                <p className='subtitle4'>with <span>20+ Built-In Games</span></p>
            </div>
            <div className="photo3">
                <a href=""><img className='bannerphoto3' src={projectimg} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /></a>
            </div>
        </div>
{/* projector section ends  */}
    
{/* buzz section starts  */}
        <div className="buzz">
        <div className="photo4">
            <video autoPlay loop muted>
            <source src={space} type="video/mp4" />
               Your browser does not support the video tag.
              </video>
        </div>
        <div className="text">
          <p className='brand'>"Top brands use immersive technology to engage over 1 million customers and drive foot traffic with interactive, memorable, experiences."</p>
          <h2>Like to create a buzz in your area</h2>
          <button className='start' onClick={openModal}>Yes, Let's Start</button>
          <p className='submit'>Submit your contact details by clicking the above button we will not spam you</p>
        </div>
      </div>
{/* buzz section ends  */}

{/* pop up contact form starts  */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
{/* pop up contact form ends         */}

{/* home section starts  */}
        <div className="home">
            <div className="heading4">
                <h2>India's 1st Home grown company</h2>
                <h4> <i>Benfits of buying with us</i> </h4>
            </div>

            <div className="item">
                <div className="item1">
                    <a href=""> <img src={warranty} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /> </a>
                    <h4>Indian Warranty</h4>
                </div>
                <div className="item2">
                    <a href=""> <img src={support} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /> </a>
                    <h4>Indian support</h4>
                </div>
                <div className="item3">
                    <a href=""> <img src={customize} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /> </a>
                    <h4>Customize games</h4>
                </div>
                <div className="item4">
                    <a href=""> <img src={delivery} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /> </a>
                    <h4>fast delivery</h4>
                </div>
                <div className="item5">
                    <a href=""> <img src={neww} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /> </a>
                    <h4>New Games</h4>
                </div>
            </div>
        </div>
{/* home section ends  */}

{/* image section starts  */}
        <div className="item-img-background">
  <div className="item-img">
    <div className="item1-img">
      <a href=""><img src={use1} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /></a>
    </div>
    <div className="item2-img">
      <a href=""><img src={use2} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /></a>
    </div>
    <div className="item3-img">
      <a href=""><img src={use3} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /></a>
    </div>
    <div className="item4-img">
      <a href=""><img src={use4} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /></a>
    </div>
    <div className="item5-img">
      <a href=""><img src={use5} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /></a>
    </div>
    <div className="item6-img">
      <a href=""><img src={use6} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" /></a>
    </div>
  </div>
</div>

{/* image section ends  */}

{/* market section starts  */}
        <div className="market-wrapper">
        <div className="market">
            <h2>Join the new age of marketing and customer experiences with Eshark</h2>
            <button  onClick={openModal}> Buy Now</button>
            <button className='explore'><a href="/" target='_blank'> Explore More</a></button>
            <p className='submitbut'>Submit your contact details by clicking the above button we will not spam you</p>
        </div>
        </div>
{/* market section ends  */}

{/* faq section starts */}
      <div className="faq-wrapper">
      <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className={`faq-question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="arrow">{activeIndex === index ? '▲' : '▼'}</span>
          </div>
          {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
    </div>
{/* faq section ends  */}
        
{/* information section starts */}
        <div className="info-wrapper">
        <div className="info">
            <div className="information">
                <p>Hi,I'm Sarath <br /> Imagine turning your space into a vibrant,engaging place where every ball throw become part of an exciting
                game.we know attracting more foot traffic and creating memorable experiences can be a challenge.That's why we've developed interactives
                wall throw games to help you stand out and draw in customers.At Eshark Digital World, we've taken on this challenge to provide you with an easy-to-install solution that brings your space to life.
                Our goal is to make your environment more exciting and inviting,so people want to visit and keep coming back. we're here to help you create experiences That
                truly make a difference.Thanks for considering us. <br /> Best, <br />Sarath </p>
            </div>

            <div className="info-pic">
                <img src={banner2img} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" />
                <div className="ceo">
                  <img src={sarath} alt="Interactive floor and wall games,Interactive games for events,Educational interactive games,Fitness and sports games,Augmented reality games,Healthcare interactive solutions,Interactive projection games,Location-based interactive games,Immersive gaming experiences,Commercial interactive gaming systems" />
                  <h3>Sarath Ceo & Founder of Eshark</h3>
                </div>

            </div>

        </div>
        </div>
        {/* information section ends  */}

    </div>
  )
}

export default Landing