import React, { useState } from 'react'
import './Health.css'
import emailjs from "emailjs-com";


import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import healthpic2 from '../Assests/healthpic2.png';
import healthpic3 from '../Assests/healthpic3.png';
import healthpic4 from '../Assests/healthpic4.png';
import healthpic5 from '../Assests/healthpic5.png';
import healthpic6 from '../Assests/healthpic6.png';
import healthpic7 from '../Assests/healthpic7.png';
import healthpic8 from '../Assests/healthpic8.png';
import healthpic9 from '../Assests/healthpic9.png';
import healthpic10 from '../Assests/healthpic10.png';
import healthpic11 from '../Assests/healthpic11.png';
import healthpic12 from '../Assests/healthpic12.png';
import healthpic13 from '../Assests/healthpic13.png';
import healthpic14 from '../Assests/healthpic14.png';
import healthpic15 from '../Assests/healthpic15.png';
import healthpic16 from '../Assests/healthpic16.png';
import healthpic17 from '../Assests/healthpic17.png';
import healthpic18 from '../Assests/healthpic18.png';
import healthpic19 from '../Assests/healthpic19.png';
import healthpic20 from '../Assests/healthpic20.png';
import healthpic21 from '../Assests/healthpic21.png';
import healthpic22 from '../Assests/healthpic22.png';
import healthpic23 from '../Assests/healthpic23.png';
import healthpic24 from '../Assests/healthpic24.png';
import healthpic25 from '../Assests/healthpic25.png';
import healthpic26 from '../Assests/healthpic26.png';
import healthpic27 from '../Assests/healthpic27.png';
import healthpic28 from '../Assests/healthpic28.png';
import healthpic29 from '../Assests/healthpic29.png';
import healthpic30 from '../Assests/healthpic30.png';


const Health = () => {

  const healthfaqs = [
    { question: " Are these games suitable for all age groups?", answer: "Yes, while our healthcare games are designed primarily for older adults and patients in rehabilitation, they can be adjusted to suit all age groups and fitness levels." },
    { question: "Can the games be customized to meet specific therapy needs?", answer: "Absolutely! Our games are fully customizable, allowing therapists to tailor them to individual patient needs and therapy goals." },
    { question: "How do the immersive experience rooms work?", answer: "We use projectors, sensors, and sound systems to create an immersive environment that responds to patient movements, providing a calming and interactive experience." },
  ]

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const[isScrolling,setIsScrolling]=useState(!0),[isDropdownOpen,setDropdownOpen]=useState({products:!1,industries:!1,support:!1}),[isHamburgerOpen,setHamburgerOpen]=useState(!1),[activeIndex,setActiveIndex]=useState(null),toggleHamburger=()=>{setHamburgerOpen(!isHamburgerOpen)},toggleDropdown=e=>{setDropdownOpen((s=>Object.keys(s).reduce(((t,n)=>(t[n]=n===e&&!s[n],t)),{})))},[isModalOpen,setIsModalOpen]=useState(!1),openModal=()=>{setIsModalOpen(!0)},closeModal=()=>{setIsModalOpen(!1)},handleClickOutside=e=>{"modal"===e.target.id&&setIsModalOpen(!1)};

        
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};
      




  return (
    <div>
{/* navbar section starts  */}
<>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" target='_blank' className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events"  target='_blank' className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness"  target='_blank' className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family"  target='_blank' className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health" className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location"  target='_blank' className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum"  target='_blank' className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical"  target='_blank' className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" />
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" target='_blank' className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events"  target='_blank' className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness"  target='_blank' className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family"  target='_blank' className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health" className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location"  target='_blank' className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum"  target='_blank' className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical"  target='_blank' className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
        </div>
      )}
    
    </>
{/* navbar section ends  */}


{/* health1 section starts */}
<div className="health1">
      <div className="healthintro1">
        <div className="healthhead1">
        <h3>Enhancing Health & Wellness with Interactive Games and Immersive Experiences</h3>
        <h5>Transforming Rehabilitation, Physiotherapy, and Mental Health Care with Innovative, Engaging Solutions</h5>
        </div>
      </div>
      </div>
{/* health1 section ends  */}

{/* health2 section starts  */}
      <div className="health2">
        <div className="healthintro2">
            <div className="healthhead2">
                <h3>Why Choose Our Interactive Healthcare Games?</h3>
                <hr className='healthhead2line' />              
                <h5>The journey to recovery, rehabilitation, and mental well-being can be challenging, especially for older adults and patients undergoing physiotherapy. At InteractiveGames.io, we believe in harnessing the power of technology to make this journey more engaging, effective, and enjoyable. Our interactive healthcare games and immersive experience rooms are designed to bring calmness, motivation, and physical activity to those in need, helping improve overall health and wellness</h5>
                <h5>Whether you’re a rehabilitation center, physiotherapy clinic, nursing home, or mental health facility, our solutions provide an innovative way to support patients through their recovery.</h5>
                
            </div>
            <div className="healthcontent1">
                <a href=""><img src={healthpic2} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <div className="healthsubcontent1">
                    <h3>Got Questions About Gaming?</h3>
                    <h5>Our experts are ready to assist</h5>
                    <button  onClick={openModal}>Ask Your Gaming Query Now!</button>
                    <h3>Game Bundle</h3>
                    <a onClick={openModal}> &#11244; Explore Now &#11246; </a>
                    <h6>Pricing Avaiable Upon Request</h6>
                </div>
            </div>   
        </div>
      </div>
{/* health2 section ends  */}

{/* pop up contact form starts  */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
        
{/* pop up contact form ends  */}

{/* health3 section starts  */}
      <div className="health3">
        <div className="healthintro3">
          <div className="healthhead3">
            <h3>Benefits of Our Interactive Games and Immersive Experiences in Healthcare</h3>
          </div>
          <div className="healthcontent2">
            <div className="healthsubcontentitem1">
               <a href=""><img src={healthpic3} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Improved Physical Rehabilitation</h5>
            </div>

            <div className="healthsubcontentitem1">
               <a href=""><img src={healthpic4} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Enhanced Cognitive Stimulation</h5>
            </div>

            <div className="healthsubcontentitem1">
               <a href=""><img src={healthpic5} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Stress Reduction and Mental Health Support</h5>
            </div>

            <div className="healthsubcontentitem1">
               <a href=""><img src={healthpic6} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Increased Patient Engagement</h5>
            </div>

            <div className="healthsubcontentitem1">
               <a href=""><img src={healthpic7} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Social Interaction & Emotional Well-being</h5>
            </div>
          </div>
        </div>
      </div>
{/* health3 section ends  */}

{/* health4 section starts  */}
      <div className="health4">
        <div className="healthintro4">
          <div className="healthhead4">
            <h3>Our Interactive Games and Solutions for Healthcare</h3>
          </div>
          <div className="healthcontent3">
            <h3>1. Body Tracking Games for Physiotherapy</h3>
            <h5>Our body-tracking games are designed to track a patient’s movements, making them ideal for physiotherapy and rehabilitation. The games encourage patients to perform specific movements that align with their therapy goals.</h5>
            <div className="healthcontent31">
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic8} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Sprint</h4>
              </div>
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic9} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Squat Jump</h4>
              </div>
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic10} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Zombie Crunch</h4>
              </div>
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic11} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Jumping Jacks</h4>
              </div>
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic12} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Smashes</h4>
              </div>

            </div>
            
          </div>
          <div className="healthcontent3">
            <h3>2. Interactive Wall Ball Throw Games for Rehabilitation</h3>
            <h5>These games combine physical therapy with fun, encouraging patients to engage in movements that aid their recovery.</h5>
            <div className="healthcontent31">
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic13} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Whack-A-Mole</h4>
              </div>
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic14} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Fruit Ninja</h4>
              </div>
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic15} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Basketball</h4>
              </div>
            </div> 
          </div>

          <div className="healthcontent3">
            <h3>3. Immersive Experience Rooms for Mental Health</h3>
            <h5>Our immersive experience rooms use projectors, sensors, and calming visuals to create a serene environment that supports mental health and relaxation. These rooms are perfect for patients dealing with anxiety, depression, or stress.</h5>
            <div className="healthcontent31">
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic16} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Nature Therapy</h4>
              </div>
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic17} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Meditation Guidance</h4>
              </div>
              <div className="healthsubcontentitem2">
                <a href=""><img src={healthpic18} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
                <h4>Sensory Stimulation</h4>
              </div>
            </div> 
          </div>

        </div>
      </div>
{/* health4 section ends  */}

{/* health5 section starts  */}
      <div className="health5">
        <div className="healthintro5">
          <div className="healthhead5">
            <h3>Who Can Benefit from Our Healthcare Games and Experiences?</h3>
          </div>
          <div className="healthcontent4">
            <div className="healthsubcontentitem3">
               <a href=""><img src={healthpic19} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Physiotherapy Clinics</h5>
            </div>

            <div className="healthsubcontentitem3">
               <a href=""><img src={healthpic20} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Rehabilitation Centers</h5>
            </div>

            <div className="healthsubcontentitem3">
               <a href=""><img src={healthpic21} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Nursing Homes & Assisted Living Facilities</h5>
            </div>

            <div className="healthsubcontentitem3">
               <a href=""><img src={healthpic22} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Mental Health Facilities</h5>
            </div>

            <div className="healthsubcontentitem3">
               <a href=""><img src={healthpic23} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Hospitals & Wellness Centers</h5>
            </div>
          </div>
        </div>
      </div>
{/* health5 section ends  */}

{/* health6 section starts  */}
      <div className="health6">
        <div className="healthintro6">
          <div className="healthhead6">
            <h3>Our Strengths in Healthcare Gaming</h3>
          </div>
          <div className="healthcontent5">
            <div className="healthsubcontentitem4">
               <a href=""><img src={healthpic24} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Patient-Centered Design</h5>
            </div>

            <div className="healthsubcontentitem4">
               <a href=""><img src={healthpic25} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Customizable Experiences</h5>
            </div>

            <div className="healthsubcontentitem4">
               <a href=""><img src={healthpic26} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Therapist Collaboration</h5>
            </div>

            <div className="healthsubcontentitem4">
               <a href=""><img src={healthpic27} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
               <h5>Easy Integration</h5>
            </div>

          </div>
        </div>
      </div>
{/* health6 section ends  */}

{/* health7 section starts  */}     
      <div className="health7">
        <div className="healthintro7">
          <div className="healthhead7">
            <h3>Why Our Healthcare Solutions Are Effective</h3>
            
          </div>
          <div className="healthcontent6">
            <div className="healthsubcontentitem5">
              <a href=""><img src={healthpic28} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
              <h4>Encourages Movement</h4>
              <h5>Interactive games make physical activity enjoyable, encouraging even reluctant patients to participate in therapy sessions.</h5>
            </div>

            <div className="healthsubcontentitem5">
              <a href=""><img src={healthpic29} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
              <h4>Promotes Independence</h4>
              <h5>Patients can control their therapy journey, gaining confidence and motivation as they see their progress.</h5>
            </div>

            <div className="healthsubcontentitem5">
              <a href=""><img src={healthpic30} alt="Physiotherapy interactive games,Healthcare gamification,Rehabilitation games for elderly,Immersive therapy experiences,Mental health interactive rooms,VR games for physiotherapy,Elderly fitness games,AR healthcare solutions,Cognitive stimulation games,Interactive therapy technology,games to stimulate your brain,puzzles to stimulate the brain" /></a>
              <h4>Creates a Positive Environment</h4>
              <h5>Our immersive experience rooms create a positive, uplifting space that supports healing and relaxation.</h5>
            </div>

          </div>
        </div>
      </div>
{/* health7 section ends  */}

{/* health8 section starts  */}
      <div className="health8">
        <div className="healthintro8">
          <div className="healthhead8">
            <h3>Get Started with Interactive Healthcare Games Today!</h3>
            <h6>Transform your healthcare facility with our innovative, interactive games and immersive experiences. Whether you’re looking to enhance physiotherapy sessions, support mental health, or create a more engaging environment for older adults, InteractiveGames.io has the perfect solution for you.</h6>
          </div>
          <div className="healthsubhead8">
            <h3>Get Started Today</h3>
            <h5>Mail: info@interactivegames.io</h5>
            <h5>Phone: +91 9876543210</h5>
            <button  onClick={openModal} >Request a Demo</button>
          </div>
        </div>
      </div>
{/* health8 section ends  */}

{/* health faq section starts  */}
      <div className="healthfaq-wrapper">
      <div className="healthfaq-container">
      <h1>Frequently Asked Questions</h1>
      {healthfaqs.map((healthfaq, index) => (
        <div key={index} className="healthfaq-item">
          <div className={`healthfaq-question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
            {healthfaq.question}
            <span className="healtharrow">{activeIndex === index ? '▲' : '▼'}</span>
          </div>
          {activeIndex === index && <div className="healthfaq-answer">{healthfaq.answer}</div>}
        </div>
      ))}
    </div>
    </div>
{/* health faq section ends  */}

            {/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}


    </div>
  )
}

export default Health