import React, { useState } from 'react'
import './Museum.css'
import emailjs from "emailjs-com";



import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import muspic2 from '../Assests/muspic2.png';
import muspic3 from '../Assests/muspic3.png';
import muspic4 from '../Assests/muspic4.png';
import muspic5 from '../Assests/muspic5.png';
import muspic6 from '../Assests/muspic6.png';
import muspic7 from '../Assests/muspic7.png';
import muspic8 from '../Assests/muspic8.png';
import muspic9 from '../Assests/muspic9.png';
import muspic10 from '../Assests/muspic10.png';
import muspic11 from '../Assests/muspic11.png';
import muspic12 from '../Assests/muspic12.png';
import muspic13 from '../Assests/muspic13.png';
import muspic14 from '../Assests/muspic14.png';
import muspic15 from '../Assests/muspic15.png';
import muspic16 from '../Assests/muspic16.png';
import muspic17 from '../Assests/muspic17.png';
import muspic18 from '../Assests/muspic18.png';
import muspic19 from '../Assests/muspic19.png';
import muspic20 from '../Assests/muspic20.png';
import muspic21 from '../Assests/muspic21.png';
import muspic22 from '../Assests/muspic22.png';


const Museum = () => {

  const museumfaqs = [
    { question: "Can the interactive exhibits be customized for different themes or subjects?", answer: "Yes, all our solutions are fully customizable to suit your exhibit's theme, subject, or target audience." },
    { question: "How much space is required for the interactive installations?", answer: "Our interactive installations can be adapted to fit various spaces, from small exhibits to large museum halls." },
    { question: " Do you provide technical support after installation?", answer: "Yes, we offer ongoing technical support and training to ensure your interactive exhibit continues to operate smoothly" },
  ]

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const[isScrolling,setIsScrolling]=useState(!0),[isDropdownOpen,setDropdownOpen]=useState({products:!1,industries:!1,support:!1}),[isHamburgerOpen,setHamburgerOpen]=useState(!1),[activeIndex,setActiveIndex]=useState(null),toggleHamburger=()=>{setHamburgerOpen(!isHamburgerOpen)},toggleDropdown=e=>{setDropdownOpen((s=>Object.keys(s).reduce(((t,n)=>(t[n]=n===e&&!s[n],t)),{})))},[isModalOpen,setIsModalOpen]=useState(!1),openModal=()=>{setIsModalOpen(!0)},closeModal=()=>{setIsModalOpen(!1)},handleClickOutside=e=>{"modal"===e.target.id&&setIsModalOpen(!1)};
        
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};

  return (
    <div>
{/* navbar section starts  */}
<>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" target='_blank' className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events"  target='_blank' className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness"  target='_blank' className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family"  target='_blank' className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health"  target='_blank' className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location"  target='_blank' className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum" className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical"  target='_blank' className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" />
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" target='_blank' className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events"  target='_blank' className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness"  target='_blank' className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family"  target='_blank' className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health"  target='_blank' className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location"  target='_blank' className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum" className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical"  target='_blank' className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
        </div>
      )}
    
    </>
{/* navbar section ends  */}

{/* museum1 section starts */}
      <div className="museum1">
      <div className="museumintro1">
        <div className="museumhead1">
        <h3>Bringing Museums & Exhibits to Life with Interactive Technology</h3>
        <h5>Elevate Visitor Engagement with Immersive Storytelling, Digital Displays, and Interactive Experiences</h5>
        </div>
      </div>
      </div>
{/* museum1 section ends */}

{/* museum2 section starts */}
      <div className="museum2">
        <div className="museumintro2">
            <div className="museumhead2">
                <h3>Why Choose Interactive Technology for Museums & Exhibits?</h3>
                <hr className='museumhead2line' />              
                <h5>In today’s digital age, visitors expect more than just static displays and information boards. They seek engaging, interactive, and memorable experiences that make learning fun and immersive. At InteractiveGames.io, we specialize in integrating cutting-edge technology to enhance museum exhibits, transforming traditional spaces into dynamic environments that captivate audiences of all ages.</h5>
                <h5>Whether you’re a history museum, science center, art exhibit, or corporate showcase, our solutions bring your stories, artifacts, and concepts to life through augmented reality (AR), virtual reality (VR), projection mapping, and interactive displays.</h5>
                
            </div>
            <div className="museumcontent1">
                <a href=""><img src={muspic2} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
                <div className="museumsubcontent1">
                    <h3>Got Questions About Gaming?</h3>
                    <h5>Our experts are ready to assist</h5>
                    <button  onClick={openModal} >Ask Your Gaming Query Now!</button>
                    <h3>Game Bundle</h3>
                    <a onClick={openModal}> &#11244; Explore Now &#11246; </a>
                    <h6>Pricing Avaiable Upon Request</h6>
                </div>
            </div>   
        </div>
      </div>
{/* museum2 section ends */}

{/* pop up contactform starts  */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
{/* pop up contactform ends  */}       

{/* museum3 section starts */}
      <div className="museum3">
        <div className="museumintro3">
          <div className="museumhead3">
            <h3>Our Capabilities for Museums & Exhibits</h3>
          </div>
          <div className="museumcontent2">
            <div className="museumsubcontentitem1">
               <a href=""><img src={muspic3} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Interactive Projection Mapping</h5>
            </div>

            <div className="museumsubcontentitem1">
               <a href=""><img src={muspic4} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Augmented Reality (AR) Experiences</h5>
            </div>

            <div className="museumsubcontentitem1">
               <a href=""><img src={muspic5} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5> Virtual Reality (VR) Immersive Experiences</h5>
            </div>

            <div className="museumsubcontentitem1">
               <a href=""><img src={muspic6} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Touchless Interactive Displays</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="museum3">
        <div className="museumintro3">
          <div className="museumhead3">
            <h3>Specialized Solutions for Different Exhibit Types</h3>
          </div>
          <div className="museumcontent2">
            <div className="museumsubcontentitem1">
               <a href=""><img src={muspic7} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5> Historical Exhibits</h5>
            </div>

            <div className="museumsubcontentitem1">
               <a href=""><img src={muspic8} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Science & Nature Exhibits</h5>
            </div>

            <div className="museumsubcontentitem1">
               <a href=""><img src={muspic9} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Art & Cultural Exhibits</h5>
            </div>

            <div className="museumsubcontentitem1">
               <a href=""><img src={muspic10} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Corporate & Brand Exhibits</h5>
            </div>
          </div>
        </div>
      </div>
{/* museum3 section ends */}

{/* museum4 section starts */}    
      <div className="museum4">
        <div className="museumintro4">
          <div className="museumhead4">
            <h3>The Benefits of Using Our Technology in Museums & Exhibits</h3>
            
          </div>
          <div className="museumcontent3">
            <div className="museumsubcontentitem2">
              <a href=""><img src={muspic11} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
              <h4>Enhanced Visitor Engagement</h4>
              <h5>Interactive technology captures the attention of visitors, encouraging them to engage with exhibits in ways that traditional displays can’t achieve. This leads to a more memorable and educational experience.</h5>
            </div>

            <div className="museumsubcontentitem2">
              <a href=""><img src={muspic12} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
              <h4>Immersive Storytelling</h4>
              <h5>Our solutions allow you to tell your stories in more dynamic and interactive ways, helping visitors connect with the content on a deeper level.</h5>
            </div>

            <div className="museumsubcontentitem2">
              <a href=""><img src={muspic13} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
              <h4>Adaptable & Customizable Solutions</h4>
              <h5>Every museum and exhibit is unique, which is why we offer fully customizable solutions tailored to your specific needs, themes, and target audience.</h5>
            </div>

            <div className="museumsubcontentitem2">
              <a href=""><img src={muspic14} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
              <h4>Increased Accessibility & Inclusivity</h4>
              <h5>Interactive experiences make learning accessible to all, catering to different learning styles, age groups, and abilities, ensuring that everyone can enjoy and benefit from the exhibit.</h5>
            </div>

          </div>
        </div>
      </div>


      
      <div className="museum4">
        <div className="museumintro4">
          <div className="museumhead4">
            <h3>Why Partner with Us?</h3>
            
          </div>
          <div className="museumcontent3">
            <div className="museumsubcontentitem2">
              <a href=""><img src={muspic15} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
              <h4>Expertise in Immersive Experience</h4>
              <h5>Our team specializes in designing interactive experiences that enhance learning and engagement, making us the ideal partner for transforming your museum or exhibit space.</h5>
            </div>

            <div className="museumsubcontentitem2">
              <a href=""><img src={muspic16} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
              <h4>End-to-End Service</h4>
              <h5> From concept development and design to installation and technical support, we provide comprehensive solutions to ensure your interactive exhibit runs smoothly.</h5>
            </div>

            <div className="museumsubcontentitem2">
              <a href=""><img src={muspic17} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
              <h4>Tailored Solutions</h4>
              <h5>We work closely with your team to understand your goals, themes, and audience, delivering customized solutions that perfectly match your vision.</h5>
            </div>

            <div className="museumsubcontentitem2">
              <a href=""><img src={muspic18} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
              <h4>Proven Track Record</h4>
              <h5>With a portfolio of successful interactive installations, we have the experience and expertise to bring your exhibit to life.</h5>
            </div>

          </div>
        </div>
      </div>
{/* museum4 section ends */}

{/* museum5 section starts */}
      <div className="museum5">
        <div className="museumintro5">
          <div className="museumhead5">
            <h3>Our Process: How We Create Immersive Museum & Exhibit Experiences</h3>
          </div>
          <div className="museumcontent4">
            <div className="museumsubcontentitem3">
               <a href=""><img src={muspic19} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Consultation & Concept Development</h5>
            </div>

            <div className="museumsubcontentitem3">
               <a href=""><img src={muspic20} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Design & Development</h5>
            </div>

            <div className="museumsubcontentitem3">
               <a href=""><img src={muspic21} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Installation & Integration</h5>
            </div>

            <div className="museumsubcontentitem3">
               <a href=""><img src={muspic22} alt="Interactive museum technology,Digital exhibits solutions,AR for museums,Projection mapping for exhibits,Virtual museum experiences,Immersive storytelling exhibits,Touchless museum displays,Museum gamification,Educational AR experiences,Museum visitor engagement,virtual reality museum,museum vr experience,online museum experiences" /></a>
               <h5>Ongoing Support</h5>
            </div>
          </div>
        </div>
      </div>
{/* museum5 section ends */}

{/* museum6 section starts */}
      <div className="museum6">
        <div className="museumintro6">
          <div className="museumhead6">
            <h3>Ready to Transform Your Museum or Exhibit?</h3>
            <h6>Elevate your museum or exhibit experience with InteractiveGames.io. Whether you're looking to engage visitors with interactive storytelling, immersive AR experiences, or dynamic projection mapping, we have the technology, creativity, and expertise to bring your vision to life.</h6>
          </div>
          <div className="museumsubhead6">
            <h3>Get Started Today</h3>
            <h5>Mail: info@interactivegames.io</h5>
            <h5>Phone: +91 9876543210</h5>
            <button  onClick={openModal} >Request a Demo</button>
          </div>
        </div>
      </div>
{/* museum6 section ends */}

{/* museumfaq section starts */}
      <div className="museumfaq-wrapper">
      <div className="museumfaq-container">
      <h1>Frequently Asked Questions</h1>
      {museumfaqs.map((museumfaq, index) => (
        <div key={index} className="museumfaq-item">
          <div className={`museumfaq-question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
            {museumfaq.question}
            <span className="museumarrow">{activeIndex === index ? '▲' : '▼'}</span>
          </div>
          {activeIndex === index && <div className="museumfaq-answer">{museumfaq.answer}</div>}
        </div>
      ))}
    </div>
    </div>
{/* museumfaq section ends */}

            {/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}


    </div>
  )
}

export default Museum