import React, { useState } from 'react'
import './Family.css'
import emailjs from "emailjs-com";


import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import fampic2 from '../Assests/fampic2.png';
import fampic3 from '../Assests/fampic3.png';
import fampic4 from '../Assests/fampic4.png';
import fampic5 from '../Assests/fampic5.png';
import fampic6 from '../Assests/fampic6.png';
import fampic7 from '../Assests/fampic7.png';
import fampic8 from '../Assests/fampic8.png';
import fampic9 from '../Assests/fampic9.png';
import fampic10 from '../Assests/fampic10.png';
import fampic11 from '../Assests/fampic11.png';
import fampic12 from '../Assests/fampic12.png';
import fampic13 from '../Assests/fampic13.png';
import fampic14 from '../Assests/fampic14.png';
import fampic15 from '../Assests/fampic15.png';
import fampic16 from '../Assests/fampic16.png';
import fampic17 from '../Assests/fampic17.png';
import fampic18 from '../Assests/fampic18.png';
import fampic19 from '../Assests/fampic19.png';
import fampic20 from '../Assests/fampic20.png';
import fampic21 from '../Assests/fampic21.png';
import fampic22 from '../Assests/fampic22.png';
import fampic23 from '../Assests/fampic23.png';
import fampic24 from '../Assests/fampic24.png';
import fampic25 from '../Assests/fampic25.png';
import fampic26 from '../Assests/fampic26.png';
import fampic27 from '../Assests/fampic27.png';
import fampic28 from '../Assests/fampic28.png';
import fampic29 from '../Assests/fampic29.png';
import fampic30 from '../Assests/fampic30.png';
import fampic31 from '../Assests/fampic31.png';
import fampic32 from '../Assests/fampic32.png';
import fampic33 from '../Assests/fampic33.png';
import fampic34 from '../Assests/fampic34.png';


const Family = () => {

  const familyfaqs = [
    { question: "Can the games be customized for different themes or events?", answer: "Yes, we can customize all our games to fit your event’s theme, branding, or requirements." },
    { question: "What is the age range for these games?", answer: "Our games are designed for all ages, from children to adults, making them perfect for family-friendly events and venues." },
    { question: "How much space is needed to set up these games?", answer: " The space required depends on the game type, but we can provide guidance and customization to fit your venue." },
  ]

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const[isScrolling,setIsScrolling]=useState(!0),[isDropdownOpen,setDropdownOpen]=useState({products:!1,industries:!1,support:!1}),[isHamburgerOpen,setHamburgerOpen]=useState(!1),[activeIndex,setActiveIndex]=useState(null),toggleHamburger=()=>{setHamburgerOpen(!isHamburgerOpen)},toggleDropdown=e=>{setDropdownOpen((s=>Object.keys(s).reduce(((t,n)=>(t[n]=n===e&&!s[n],t)),{})))},[isModalOpen,setIsModalOpen]=useState(!1),openModal=()=>{setIsModalOpen(!0)},closeModal=()=>{setIsModalOpen(!1)},handleClickOutside=e=>{"modal"===e.target.id&&setIsModalOpen(!1)};

        
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};
      




  return (
    <div>
{/* navbar section starts  */}
<>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" target='_blank' className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events"  target='_blank' className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness"  target='_blank' className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family" className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health"  target='_blank' className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location"  target='_blank' className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum"  target='_blank' className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical"  target='_blank' className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" />
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" target='_blank' className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events"  target='_blank' className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness"  target='_blank' className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family" className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health"  target='_blank' className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location"  target='_blank' className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum"  target='_blank' className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical"  target='_blank' className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
        </div>
      )}
    
    </>
{/* navbar section ends  */}

{/* family1 section starts */}
<div className="family1">
      <div className="familyintro1">
        <div className="familyhead1">
        <h3>Unleash the Fun with Our Interactive Entertainment Games</h3>
        <h5> Bringing Immersive, Interactive, and Customizable Experiences to Amusement Parks, Play Areas, Events, and More</h5>
        </div>
      </div>
      </div>
{/* family1 section ends  */}

{/* family2 section starts  */}
      <div className="family2">
        <div className="familyintro2">
            <div className="familyhead2">
                <h3>Why Choose Our Entertainment Games?</h3>
                <hr className='familyhead2line' />              
                <h5>Entertainment is evolving, and audiences today crave more than just passive experiences. They want to be part of the action! At InteractiveGames.io, we specialize in delivering cutting-edge interactive entertainment games that engage, excite, and entertain audiences of all ages. Whether you’re looking to enhance your amusement park, indoor play area, arcade, retail store, or corporate event, our games are designed to bring the WOW factor.</h5>
                
            </div>
            <div className="familycontent1">
                <a href=""><img src={fampic2} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
                <div className="familysubcontent1">
                    <h3>Got Questions About Gaming?</h3>
                    <h5>Our experts are ready to assist</h5>
                    <button  onClick={openModal} >Ask Your Gaming Query Now!</button>
                    <h3>Game Bundle</h3>
                    <a onClick={openModal}> &#11244; Explore Now &#11246; </a>
                    <h6>Pricing Avaiable Upon Request</h6>
                </div>
            </div>   
        </div>
      </div>
{/* family2 section ends  */}

{/* pop up contact form section starts  */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
        
{/* pop up contact form section ends  */}

{/* family3 section starts  */}
      <div className="family3"> 
        <div className="familyintro3">
          <div className="familyhead3">
            <h3>Our Strengths: What Sets Us Apart?</h3>
            
          </div>
          <div className="familycontent2">
            <div className="familysubcontentitem2">
              <a href=""><img src={fampic3} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
              <h4>Wide Range of Games</h4>
              <h5>From action-packed interactive wall games to body-tracking experiences, we offer a variety of entertainment options suitable for any event or venue.</h5>
            </div>

            <div className="familysubcontentitem2">
              <a href=""><img src={fampic4} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
              <h4>Customizable Experiences</h4>
              <h5> Our games can be tailored to match your theme, brand, or event requirements, ensuring a unique and personalized experience.</h5>
            </div>
            <div className="familysubcontentitem2">
              <a href=""><img src={fampic5} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
              <h4>Engaging & Immersive</h4>
              <h5>Using the latest technology, we create games that respond to players’ movements, turning them into active participants rather than passive spectators.</h5>
            </div>

            <div className="familysubcontentitem2">
              <a href=""><img src={fampic6} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
              <h4>Turnkey Solutions</h4>
              <h5>We handle everything from game installation to support, making it easy to integrate our entertainment games into your space.</h5>
            </div>
          </div>
        </div>
      </div>
{/* family3 section ends  */}

{/* family4 section starts  */}
      <div className="family4">
        <div className="familyintro4">
          <div className="familyhead4">
            <h3>Entertainment Games: The Ultimate Collection</h3>
            <h5>Our range of entertainment games includes Interactive Wall Ball Throw Games, Body Tracking Games, and Fitness Games, each designed to create memorable experiences. Here's a breakdown of the games we offer:</h5>
          </div>
        </div>
      </div>
{/* family4 section ends  */}

{/* family5 section starts  */}
      <div className="family5">
        <div className="familyintro5">
          <div className="familyhead5">
            <h3>Interactive Wall Ball Throw Games</h3>
            <h5>These games transform any wall into an interactive gaming experience, using IR camera technology to detect ball throws and player movements.</h5>
          </div>
          <div className="familycontent3">
            <div className="familysubcontentitem3">
               <a href=""><img src={fampic7} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Fruit Ninja</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic8} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Plane Smash</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic9} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Goat Catch</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic10} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5> Airship Smash</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic11} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Whack-A-Mole</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic12} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Octopus Hunt</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic13} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5> Gemstone Glide</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic14} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Basketball</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic15} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Stellar Strike</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic16} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Monster Hunt</h5>
            </div>

            <div className="familysubcontentitem3">
               <a href=""><img src={fampic17} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5> Master Puzzle</h5>
            </div>
          </div>
        </div>
      </div>
{/* family5 section ends  */}

{/* family6 section starts  */}
      <div className="family6">
        <div className="familyintro6">
          <div className="familyhead6">
            <h3>Body Tracking Games</h3>
            <h5>Our body-tracking games use advanced camera technology to detect players’ movements, turning their bodies into game controllers.</h5>
          </div>
          <div className="familycontent4">
            <div className="familysubcontentitem4">
               <a href=""><img src={fampic18} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5> Move'N Pick</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic19} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Fill The Basket</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic20} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Palayok Game (Uriyadi)</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic21} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Jungle Run</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic22} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5> Speed Surge</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic23} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Skyline Skies</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic24} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Excitement Meter</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="family6">
        <div className="familyintro6">
          <div className="familyhead6">
            <h3>Fitness & Sports Games</h3>
            <h5>These games combine fitness with fun, making them ideal for gyms, fitness facilities, and corporate wellness programs.</h5>
          </div>
          <div className="familycontent4">
            <div className="familysubcontentitem4">
               <a href=""><img src={fampic25} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Sprint</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic26} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5> Squat Jump</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic27} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Zombie Crunch</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic28} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Jumping Jacks</h5>
            </div>

            <div className="familysubcontentitem4">
               <a href=""><img src={fampic29} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Smashes</h5>
            </div>
          </div>
        </div>
      </div>
{/* family6 section ends  */}

{/* family7 section starts  */}
      <div className="family7">
        <div className="familyintro7">
          <div className="familyhead7">
            <h3>Who Can Benefit from Our Entertainment Games?</h3>
            <h5>Our interactive games are perfect for a wide range of industries and venues:</h5>
          </div>
          <div className="familycontent5">
            <div className="familysubcontentitem5">
               <a href=""><img src={fampic30} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Amusement Parks & Indoor Play Areas</h5>
            </div>

            <div className="familysubcontentitem5">
               <a href=""><img src={fampic31} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Arcades & Entertainment Centers</h5>
            </div>

            <div className="familysubcontentitem5">
               <a href=""><img src={fampic32} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Retail Stores & Malls</h5>
            </div>

            <div className="familysubcontentitem5">
               <a href=""><img src={fampic33} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Events & Exhibitions</h5>
            </div>

            <div className="familysubcontentitem5">
               <a href=""><img src={fampic34} alt="Interactive entertainment experiences,AR games for amusement parks,Body tracking games for entertainment,Virtual reality entertainment games,Touchless gaming experiences,Custom entertainment games,Projection games for family fun centers,Gamification for entertainment venues,Interactive floor and wall games,Multisensory gaming experiences,vr entertainment games,fun vr games for family,projection games for family fun centers" /></a>
               <h5>Corporate Events & Team Building</h5>
            </div>
          </div>
        </div>
      </div>
{/* family7 section ends  */}

{/* family8 section starts  */}
      <div className="family8">
        <div className="familyintro8">
          <div className="familyhead8">
            <h3>Customizable Experiences for Every Event</h3>
            <h6>We understand that every event is unique, which is why we offer fully customizable game options. Whether you want to integrate your brand, create a themed experience, or develop a completely new game concept, we have the expertise to make it happen.</h6>
          </div>
          <div className="familysubhead8">
            <h3>Get Started Today</h3>
            <h5>Mail: info@interactivegames.io</h5>
            <h5>Phone: +91 9876543210</h5>
            <button  onClick={openModal} >Request a Demo</button>
          </div>
        </div>
      </div>
{/* family8 section ends  */}

{/* familyfaq section starts  */}
      <div className="familyfaq-wrapper">
      <div className="familyfaq-container">
      <h1>Frequently Asked Questions</h1>
      {familyfaqs.map((familyfaq, index) => (
        <div key={index} className="familyfaq-item">
          <div className={`familyfaq-question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
            {familyfaq.question}
            <span className="familyarrow">{activeIndex === index ? '▲' : '▼'}</span>
          </div>
          {activeIndex === index && <div className="familyfaq-answer">{familyfaq.answer}</div>}
        </div>
      ))}
    </div>
    </div>
{/* familyfaq section ends  */}

            {/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}


    </div>
  )
}

export default Family