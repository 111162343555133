import React, { useState,useRef, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Home.css'
import StructuredData from '../StructuredDataScript/StructuredDataScript';
import axios from 'axios';
import emailjs from "emailjs-com";
import { Link } from 'react-router-dom';





// Import images
import frame1 from '../Assests/Interactive Floor.png';
import frame2 from '../Assests/Interactive Wall.png';
import frame3 from '../Assests/Agumented somatosensory interactive games.png';
import frame4 from '../Assests/Interactive Dynamic Bicycle.png';
import frame5 from '../Assests/Interactive Sandbox.png';
import frame6 from '../Assests/Immersive Interactive room.png';
import frame7 from '../Assests/Bowling Interactive Projection.png'
import frame8 from '../Assests/Interactive Sports.png'
import frame9 from '../Assests/AR Gmaes.png'
import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import park from '../Assests/park.png';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import budget from '../Assests/budget.svg';
import high from '../Assests/high.svg';
import optimal from '../Assests/foot.svg';
import quality from '../Assests/quality.svg';
import roi from '../Assests/quick.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import video from '../Assests/video.mp4';
import game1 from '../Assests/Game1.PNG';
import game2 from '../Assests/Game2.PNG';
import game3 from '../Assests/Game3.PNG';
import game4 from '../Assests/Game4.PNG';
import game5 from '../Assests/Game5.PNG';
import game6 from '../Assests/Game6.PNG';
import game7 from '../Assests/Game7.PNG';
import game8 from '../Assests/Game8.PNG';
import game9 from '../Assests/Game9.PNG';
import game10 from '../Assests/Game10.PNG';
import game11 from '../Assests/Game11.PNG';
import game12 from '../Assests/Game12.PNG';
import game13 from '../Assests/Game13.PNG';
import game14 from '../Assests/Game14.PNG';
import game15 from '../Assests/Game15.PNG';
import game16 from '../Assests/Game16.PNG';
import game17 from '../Assests/Game17.PNG';
import game18 from '../Assests/Game18.PNG';
import game19 from '../Assests/Game19.PNG';
import game20 from '../Assests/Game20.PNG';
import game21 from '../Assests/Game21.PNG';
import game22 from '../Assests/Game22.PNG';
import game23 from '../Assests/Game23.PNG';
import game24 from '../Assests/Game24.PNG';
import game25 from '../Assests/Game25.PNG';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
 


// Scrolling images
const images = [
  game1,game2,game3,game4,game5,game6,game7,game8,game9,game10,
  game11,game12,game13,game14,game15,game16,game17,game18,game19,game20,
  game21,game22,game23,game24,game25   
];

//Content images
const contentData = [
  { image: frame1, title: 'INTERACTIVE FLOOR', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Floor Gaming Projection Visual,Ground-Based Interctive Play,Interactive Floor Game Setup', description: 'Transform any floor into a captivating, interactive experience with our state-of-the-art Interactive Floor. Perfect for play areas, retail spaces, and events, this technology responds to every step and movement, creating a dynamic environment that engages and entertains users of all ages.'},
  { image: frame2, title: 'INTERACTIVE WALL', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Wall Projection Game Visual,Digital Wall Gaming Setup,Interactive Wall Play', description: 'Elevate your space with our Interactive Wall, where digital art, games, and visuals come to life with the touch of a hand. Ideal for educational institutions, museums, and entertainment venues, this innovative solution offers immersive, touch-sensitive experiences that captivate and inspire.' },
  { image: frame3, title: 'AGUMENTED SOMATOSENSORY INTERACTIVE GAMES', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games, Augmented Reality Gaming Visual,Sensory Interactive Interface,AR Somatosensory Game Setup', description: 'Engage all the senses with our Augmented Somatosensory Interactive Games. Combining physical movement with digital interaction, these games offer a fully immersive experience that enhances motor skills, coordination, and sensory perception, perfect for rehabilitation centers, preschools, and play areas.' },
  { image: frame4, title: 'INTERACTIVE DYNAMIC BICYCLE', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Cycling Game Interface Visual,Dynamic Bicycle Gaming Setup,Fitness Cycling Game Visuals', description: 'Ride into a new dimension with our Interactive Dynamic Bicycle. This innovative product combines physical cycling with interactive gameplay, creating an exciting, health-focused experience that’s perfect for fitness centers, schools, and recreational areas.' },
  { image: frame5, title: 'INTERACTIVE SANDBOX', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Sandbox Gaming Interface Visual,Digital Sandbox Play Visual,Engaging Sandbox Experience', description: 'Let imagination take the lead with our Interactive Sandbox. This cutting-edge product transforms a simple sandbox into a dynamic learning tool, where children can interact with digital content through touch and movement, making it an ideal addition to schools, museums, and play zones.' },
  { image: frame6, title: 'IMMERSIVE INTERACTIVE ROOM', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Immersive Room Gaming Visual,360-Degree Interactive Setup,Full-Room Gaming Experience', description: 'Step into a world of endless possibilities with our Immersive Interactive Room. Designed to create a fully engaging environment, this product blends visuals, sound, and touch into a seamless interactive experience, perfect for museums, entertainment centers, and immersive exhibitions.' },
  { image: frame7, title: 'BOWLING INTERACTIVE PROJECTION', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Bowling Projection Game Visual,Interactive Bowling Setup,Digital Bowling Gaming Interface', description: 'Add a digital twist to traditional bowling with our Bowling Interactive Projection. This product overlays exciting visuals and games onto a bowling lane, creating a dynamic and interactive experience that appeals to players of all ages in entertainment venues and bowling alleys.' },
  { image: frame8, title: 'INTERACTIVE SPORTS GAMES', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Real-Time Sports Simulation Games,Virtual Fitness and Sports Challenges,High-Tech Sports Training Games', description:"Dive into a world where sports meet technology! Interactive sports games offer dynamic, real-time experiences, blending physical activity with digital enhancements for a truly engaging workout or competition. Whether it's a virtual soccer match or a high-tech fitness challenge, these games make staying active fun and exciting."  },
  { image: frame9, title: 'AGUMENTED REALITY GAMES', alttext:'interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games, AR-Enhanced Gaming Experiences,Immersive Augmented Reality Adventures,Digital Overlay Games with Augmented Reality', description: "Step into a new reality with augmented reality (AR) games that blend the digital and physical worlds. Using AR technology, these games overlay digital elements onto the real world, creating immersive and interactive experiences. Explore your surroundings like never before, solve puzzles, and embark on adventures with just your smartphone or AR glasses." }
];



const ContentBox = ({ image, title, description,alttext }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="content-box"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered ? 'rgba(14, 34, 64)' : 'white',
        color: isHovered ? 'white' : 'rgba(14, 34, 64)',
      }}
    >
      <img src={image} title={title} alt={alttext}  />
      <h3 style={{ color: isHovered ? 'white' : 'rgba(14, 34, 64)' }}>{title}</h3>
      <p style={{ color: isHovered ? 'white' : 'rgba(14, 34, 64)' }}>{description}</p>
      <a href=""><button
        style={{
          backgroundColor: isHovered ? 'white' : 'rgba(14, 34, 64)',
          color: isHovered ? 'rgba(14, 34, 64)' : 'white',
        }} 
      > GET A QUOTE
      </button></a>
    </div>

    
  );
};




const Home = () => {
    const [playing, setPlaying] = useState(false);
    const [isScrolling, setIsScrolling] = useState(true);
    const [isDropdownOpen, setDropdownOpen] = useState({ products: false, industries: false, support: false });
    const [isHamburgerOpen, setHamburgerOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);

    
const ContentBox = ({ image, title, description,alttext }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="content-box"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered ? 'rgba(14, 34, 64)' : 'white',
        color: isHovered ? 'white' : 'rgba(14, 34, 64)',
      }}
    >
      <img src={image} title={title} alt={alttext}  />
      <h3 style={{ color: isHovered ? 'white' : 'rgba(14, 34, 64)' }}>{title}</h3>
      <p style={{ color: isHovered ? 'white' : 'rgba(14, 34, 64)' }}>{description}</p>
      <button onClick={openModal}
        style={{
          backgroundColor: isHovered ? 'white' : 'rgba(14, 34, 64)',
          color: isHovered ? 'rgba(14, 34, 64)' : 'white',
        }} 
      > GET A QUOTE
      </button>
    </div>

    
  );
};


    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const handleClickOutside = (event) => {
      if (event.target.id === 'modal') {
        closeModal();
      }
    };
  
    const toggleHamburger = () => {
      setHamburgerOpen(!isHamburgerOpen);
    };

    const toggleDropdown = (dropdownName) => {
      setDropdownOpen((prevState) => {
        // Close all other dropdowns and only open the clicked one
        const newState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = key === dropdownName ? !prevState[key] : false; // Close all others
          return acc;
        }, {});
        return newState;
      });
    };
    
    
    useEffect(() => {
        // Check if it's the user's first visit
        const isFirstVisit = localStorage.getItem('isFirstVisit');
        
        if (!isFirstVisit) {
            // Auto-play the video on the first visit
            setPlaying(true);
            localStorage.setItem('isFirstVisit', 'true');
        }
    }, []);

  const marqueeRef = useRef(null);
 

  // Calculate and set the width of .images-wrapper dynamically
  useEffect(() => {
    if (marqueeRef.current) {
      const imageWidth = marqueeRef.current.querySelector('img').offsetWidth;
      const totalWidth = imageWidth * images.length; // Width to fit all images
      marqueeRef.current.style.width = `${totalWidth}px`;
    }
  }, [images]);

  const handleImageClick = () => {
    setIsScrolling(false);
    // Enable manual scrolling
    marqueeRef.current.style.overflowX = 'auto';
    marqueeRef.current.style.cursor = 'grab';
  };

  useEffect(() => {
    if (isScrolling) {
      marqueeRef.current.style.animationPlayState = 'running';
      // marqueeRef.current.style.overflowX = 'hidden'; // Hide overflow during auto-scrolling
    } else {
      marqueeRef.current.style.animationPlayState = 'paused';
    }
  }, [isScrolling]);

  
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     mobile: '',
//     company: '',
//     companyType: '',
//     jobTitle: '',
//   });
//   const [responseMessage, setResponseMessage] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//    Handler for input changes
//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//    Handler for form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const apiUrl ='http://<89.116.53.195>:5001/send-email';

//   try {
//     const response = await axios.post(apiUrl, formData);
//     setResponseMessage(response.data);
//     setErrorMessage('');
//   } catch (error) {
//     setErrorMessage('Error sending email');
//     setResponseMessage('');
//   }
// };

const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};




    return (
      
      <div className='container'>
       
       <>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" target='_blank' className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events"  target='_blank' className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness"  target='_blank' className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family"  target='_blank' className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health"  target='_blank' className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location"  target='_blank' className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum"  target='_blank' className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical"  target='_blank' className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" /> 
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" target='_blank' className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events"  target='_blank' className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness"  target='_blank' className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family"  target='_blank' className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health"  target='_blank' className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location"  target='_blank' className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum"  target='_blank' className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical"  target='_blank' className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
        </div>
      )}
    
    </>
      
      
    {/* Video Section starts */}
    <div className="full-width-section">
  <div className="centered-content">
    <div className="video-container">
      <video src={video} autoPlay loop muted alt="interactive software, best game development software, game development company, video game development, game creation software, etc."></video>
    </div>
  </div>
</div>

          {/* Video section ends  */}

      {/* solution section starts  */}
      <div className="sol">
    <div className='solution'>
    <h1 className='title1'>Complete Solutions For <span><i>Every Industry</i></span> - Find Yours</h1>
    <p className='subtitle1'>Your Industry , Our Expertise - Tailored Solutions For Every Need</p>
    <div className='gridd'>
        <div className='gridd-item1'>
          <a href='/Education' target='_blank'><img className='education' src={education} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Educational Games,Interactive Learning Games,Educational Digital Experiences,Gamified Learning Platforms'/></a>
            <h4>Educational Games</h4>
        </div>
        <div className='gridd-item2'>
          <a href='/Events' target='_blank'> <img className='event' src={event} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Event Games,Interactive Event Experiences,Engaging Event Games,Event-Based Interactive Gaming' /></a>
            <h4>Event Games</h4>
        </div>
        <div className='gridd-item3'>
          <a href='/Fitness' target='_blank'> <img className='fitness' src={fitness} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Fitness & sports Games,Fitness Interactive Games,Sports Simulation Games,Active Lifestyle Gaming' /> </a>
            <h4>Fitness & Sports Games </h4>
        </div>
        <div className='gridd-item4'>
          <a href='/Family' target='_blank'>  <img className='enterainment' src={entertainment} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Entertainment Games,Interactive Entertainment Games,Fun & Engagin Digital Games,Entertainment-Focused Gaming Experiences' /></a>
            <h4>Entertainment Games</h4>
        </div>
        <div className='gridd-item5'>
          <a href='/Health' target='_blank'> <img className='health' src={health} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Healthcare Games,Health & Wellness Interactive Games,Therapeutic Gaming Solutions,Healthcare Training Games' /></a>
            <h4>Healthcare Games</h4>
        </div>
        <div className='gridd-item6'>
          <a href='/Location' target='_blank'> <img className='location' src={location} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Location-Based Games,Location-Driven Interactive Games,GPS-Enabled Gaming Experiences,Augmented Reality Location Games' /></a>
            <h4>Location-Based Entertainment</h4>
        </div>
        <div className='gridd-item7'>
          <a href='/Museum' target='_blank'> <img className='museum' src={museum} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Museums & Exhibits,Interactive Museum Games,Exhibit-Based Gaming Experience,Cultural Interactive Experiences' /></a>
            <h4>Museums & Exhibits</h4>
        </div>
        <div className='gridd-item8'>
          <a href='/Commerical' target='_blank'> <img className='commerical' src={commerical} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Commerical Games,Bussiness-Focused Interactive Games,Corporate Training & Engagement Games,Commerical Gamification Solutions' /></a>
            <h4>Commercial Games</h4>
        </div>
    </div>
 </div>
 </div>

            {/* solution section ends  */}


            {/* Content section starts  */}
    <div className="con">
    <div className="content-section">
      <h1 className="title">Engage, Interact And Innovate - <span><i> Explore Our Game</i></span> Categories</h1>
      <p className="subtitlee">Unleashing Fun Across Every Dimension</p>
      <div className="content-box-wrapper">
        {contentData.map((item, index) => (
          <ContentBox
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
            alttext={item.alttext}
          />
        ))}
      </div>
    </div>
    </div>

    
        
        {/* Content section ends  */}

        {/* Slider section starts  */}
      <div className="marquee-container">
      <div className="title-container">
        <h1 className="title">Discover, Play And Experience Dive Into <span><i>Our Top Games</i></span> </h1>
        <h2 className="subtitle">Where Every Game is a New Adventure</h2>
      </div>
      <div className="marquee" ref={marqueeRef} onClick={handleImageClick}>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Image ${index + 1}`} onClick={handleImageClick} />
        ))}
        {images.map((image, index) => (
          <img key={index + images.length} src={image} alt={`Image ${index + 1}`} onClick={handleImageClick}  />
        ))} 

      </div>
    </div>

     {/* Slider section ends  */}
      
       {/* Park section starts   */}
      <div className="parkk">
      <div className='park'>
      <h1>Want To Build An <span><i>Interactive Park</i></span> </h1>
      <p>Create Unforgettable Outdoor Experiences With Our Interactive Park solutions, Where Technology Meets Nature To Engage, Entertain, And Inspire Visitors Of All Ages.</p>
      <button onClick={openModal}>TO KNOW MORE</button>
      <img src={park} alt="interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Interactive Park,Innovative Outdoor Play space for Kids, Engaging Park Equipment for Outdoor Fun,Dynamic Play area with Interactive Features" />
      </div>
      </div>
        {/* Park section ends  */}

        {/* Customer section starts  */}
      <div className="cust">
      <div className='Customer'>
    <h1 className='title'>Designed to <span><i>WOW Customers</i></span> And Operators</h1>
    <p className='subtitle'>Crafted to Impress, Built to Perform</p>
    <div className='grid'>
        <div className='grid-item1'>
          <a href=''><img className='optimal' src={optimal} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Optimal Footprint,Efficient Space Utilization for interactive features,Compact Interactive Solutions for Limited Spaces,Spac-Efficient Design for Maximum Impact'/></a>
            <h4>Optimal Footprint</h4>
        </div>
        <div className='grid-item2'>
          <a href=''> <img className='budget' src={budget} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Budget Friendly Interactive Games, Affordable Entertainment Options,Cost-Effective Interactive Solutions for All,High-Quality Interactive Features at Lower Costs' /></a>
            <h4>Budget Friendly</h4>
        </div>
        <div className='grid-item3'>
          <a href=''> <img className='high' src={high} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,High Repeat Value,Interactive Games with Long-Term Engagement,Games Designed for Continuous User Interaction,Fun and Engaging Interactive Experiences for Repeated Use' /> </a>
            <h4>High Repeat Value</h4>
        </div>
        <div className='grid-item4'>
          <a href=''>  <img className='roi' src={roi} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,Quick ROI,Interactive Solutions for Rapid Return on Investment,Fast-Acting Interactive Features for Immediate Results,Solutions Designed to Deliver Fast Financial Returns' /></a>
            <h4>Quick ROI</h4>
        </div>
        <div className='grid-item5'>
          <a href=''> <img className='quality' src={quality} alt='interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games,100% Quality,Premium Interactive Games for Top Performance,High-Standard Interactive Solutions with Guaranteed Excellence,Superior Interactive Games with Unmatched Relaibility' /></a>
            <h4>100% Quality</h4>
        </div>
    </div>
  </div>
  </div>

  {/* Customer section ends  */}

        {/* Contact us section starts   */}
        <div className="cont">
        <div className="contact">
      <div className="header">
        <h1>How Can We Help You?</h1>
        <p className="subheading">Tell Us About Your Business</p>
      </div>
      <form className="contact-form" onSubmit={sendEmail}>
        <input 
          type="text" 
          id="name" 
          name="name" 
          required 
          placeholder="Full Name" 
          value={formData.name} 
          onChange={handleChange} 
        />
        <input 
          type="email" 
          id="email" 
          name="email" 
          required 
          placeholder="Email" 
          value={formData.email} 
          onChange={handleChange} 
        />
        <input 
          type="tel" 
          id="mobile" 
          name="mobile" 
          required 
          placeholder="Mobile Number" 
          value={formData.mobile} 
          onChange={handleChange} 
        />
        <button type="submit">Submit</button>
      </form>
      {responseMessage && <p className="success-messagee">{responseMessage}</p>}
      {errorMessage && <p className="error-messagee">{errorMessage}</p>}
      <div className="additional-info">
        <label>
          <input type="checkbox" />
          <span className='check'>Check this box to receive product info and exclusive deals from Eshark.</span>
        </label>
        <p className="info-text">
          By clicking submit, you agree to receive recurring messages from Eshark, Inc. Reply STOP to opt out; Reply HELP for help; Message frequency varies; Message and data rates may apply; Carriers are not liable for delayed or undelivered messages.I also agree to the Terms and Conditions and Privacy Policy, including communication via email, calls, and other digital means from and on behalf of Eshark.
        </p>
      </div>
    </div>
    </div>
    {/* Contact us section ends  */}

        {/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}
      
 
    </div>
    
  
  );
  
}

export default Home
