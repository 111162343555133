import React, { useState } from 'react'
import './Education.css'
import emailjs from "emailjs-com";

import floor from '../Assests/interactive floor.svg';
import wall from '../Assests/Interactive wall.svg';
import somato from '../Assests/Body Tracking.svg';
import bicycle from '../Assests/Cycle.svg';
import sandbox from '../Assests/Sandbox.svg';
import immersive from '../Assests/Interactive room.svg';
import bowling from '../Assests/Bowling.svg';
import education from '../Assests/education.svg';
import event from '../Assests/event.svg';
import fitness from '../Assests/fitness.svg';
import entertainment from '../Assests/entertain.svg';
import health from '../Assests/health.svg';
import location from '../Assests/location.svg';
import museum from '../Assests/museum.svg';
import commerical from '../Assests/commercial.svg';
import faq from '../Assests/question.svg';
import live from '../Assests/live-chat.svg';
import facebook from '../Assests/face.svg';
import linkedin from '../Assests/link.svg';
import insta from '../Assests/insta.svg';
import whatsapp from '../Assests/whatsapp.svg';
import phone from '../Assests/Frame 55.png';
import mail from '../Assests/Frame 56.png';
import education1 from '../Assests/education1.png';
import boost from '../Assests/boost.png';
import enhance from '../Assests/enhance.png';
import foster from '../Assests/foster.png';
import spark from '../Assests/spark.png';
import promote from '../Assests/promote.png';
import active from '../Assests/active.png';
import learn from '../Assests/learn.png';
import holistic from '../Assests/holistic.png';
import experience from '../Assests/experience.png';
import stimulation from '../Assests/stimulation.png';
import engage from '../Assests/engage.png';
import eduenvpic from '../Assests/eduenvpic.png';
import eduenvpic1 from '../Assests/eduenvpic1.png';
import eduenvpic2 from '../Assests/eduenvpic2.png';
import eduenvpic3 from '../Assests/eduenvpic3.png';
import featimg1 from '../Assests/featimg1.png';
import featimg2 from '../Assests/featimg2.png';
import featimg3 from '../Assests/featimg3.png';
import featimg4 from '../Assests/featimg4.png';


const Education = () => {
  const[isScrolling,setIsScrolling]=useState(!0),[isDropdownOpen,setDropdownOpen]=useState({products:!1,industries:!1,support:!1}),[isHamburgerOpen,setHamburgerOpen]=useState(!1),[activeIndex,setActiveIndex]=useState(null),toggleHamburger=()=>{setHamburgerOpen(!isHamburgerOpen)},toggleDropdown=e=>{setDropdownOpen((t=>Object.keys(t).reduce(((s,n)=>(s[n]=n===e&&!t[n],s)),{})))},toggleFAQ=e=>{setActiveIndex(e===activeIndex?null:e)},[isModalOpen,setIsModalOpen]=useState(!1),openModal=()=>{setIsModalOpen(!0)},closeModal=()=>{setIsModalOpen(!1)},handleClickOutside=e=>{"modal"===e.target.id&&setIsModalOpen(!1)};

        
const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
 
});

const [responseMessage, setResponseMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .send(
      "service_7r0rz2r",   // Replace with your EmailJS Service ID
      "template_v4ckdud",  // Replace with your EmailJS Template ID
      formData,
      "SYyDXLvMlYo_jZxBO"       // Replace with your EmailJS User ID
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setResponseMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          
        });
      },
      (error) => {
        console.log("FAILED...", error);
        setErrorMessage("Failed to send the message. Please try again.");
      }
    );
};
      


  return (
    <div>

{/* Navbar section starts  */}
<>
       <div className="nav">
      <nav className="navbar">
        <div className="navbar-content">
        <div className="navbar-left">
          <h1>INTERACTIVE GAMES</h1>
          <p className="powered-by">POWERED BY ESHARK</p>
        </div>
        <div className="navbar-right">
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("products") }>
              PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.products && (
              <div className={`dropdown-menu ${isDropdownOpen.products ? "show" : ""}`}>
                <a href="#product1" className="dropdown-item">
                  <img src={floor} alt="Product 1" className="dropdown-image" />
                  <span>Interactive Floor</span>
                </a>
                <a href="#product2" className="dropdown-item">
                  <img src={wall} alt="Product 2" className="dropdown-image" />
                  <span>Interactive Wall</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={somato} alt="Product 3" className="dropdown-image" />
                  <span>Augumented Somatosensory Interactive Games</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bicycle} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Dynamic Bicycle</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={sandbox} alt="Product 3" className="dropdown-image" />
                  <span>Interactive Sandbox</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={immersive} alt="Product 3" className="dropdown-image" />
                  <span>Immersive Interactive Room</span>
                </a>
                <a href="#product3" className="dropdown-item">
                  <img src={bowling} alt="Product 3" className="dropdown-image" />
                  <span>Bowling Interactive Projection</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
              INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.industries && (
              <div className="dropdown-menu">
                <a href="/Education" className="dropdown-item">
                  <img src={education} alt="Industry 1" className="dropdown-image" />
                  <span>Educational Games</span> 
                </a>
                <a href="/Events"  target='_blank' className="dropdown-item">
                  <img src={event} alt="Industry 2" className="dropdown-image" />
                  <span>Event Games</span>
                </a>
                <a href="/Fitness"  target='_blank' className="dropdown-item">
                  <img src={fitness} alt="Industry 3" className="dropdown-image" />
                  <span>Fitness & Sports Games</span>
                </a>
                <a href="/Family"  target='_blank' className="dropdown-item">
                  <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                  <span>Family Entertainment Centers</span>
                </a>
                <a href="/Health"  target='_blank' className="dropdown-item">
                  <img src={health} alt="Industry 3" className="dropdown-image" />
                  <span>Healthcare Games</span>
                </a>
                <a href="/Location"  target='_blank' className="dropdown-item">
                  <img src={location} alt="Industry 3" className="dropdown-image" />
                  <span>Location-Based Entertainment</span>
                </a>
                <a href="/Museum"  target='_blank' className="dropdown-item">
                  <img src={museum} alt="Industry 3" className="dropdown-image" />
                  <span>Museums & Exhibits</span>
                </a>
                <a href="/Commerical"  target='_blank' className="dropdown-item">
                  <img src={commerical} alt="Industry 3" className="dropdown-image" />
                  <span>Commerical</span>
                </a>
              </div>
            )}
          </div>
          <div className="dropdown">
            <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
              SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
            </button>
            {isDropdownOpen.support && (
              <div className="dropdown-menu">
                <a href="#support1" className="dropdown-item">
                  <img src={faq} alt="Support 1" className="dropdown-image" />
                  <span>FAQ</span>
                </a>
                <a href="#support2" className="dropdown-item">
                  <img src={live} alt="Support 2" className="dropdown-image" />
                  <span>Live Chat</span>
                </a>
                
              </div>
            )}
          </div>
          <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
        </div>
        <button className="hamburger" onClick={toggleHamburger}>
          &#9776;
        </button>
        </div>
        
      </nav>
      </div>

      {isHamburgerOpen && (
        <div className="hamburger-menu">
          <button className="close-btn" onClick={toggleHamburger}>
            &times;
          </button>
          <div className="hamburger-dropdowns">
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("products")}>
                PRODUCTS <span className={`triangle ${isDropdownOpen.products ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.products && (
                <div className="dropdown-menu full-width">
                  <a href="#product1" className="dropdown-item">
                    <img src={floor} alt="Product 1" className="dropdown-image" />
                    <span>Interactive Floor</span>
                  </a>
                  <a href="#product2" className="dropdown-item">
                    <img src={wall} alt="Product 2" className="dropdown-image" />
                    <span>interactive Wall</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={somato} alt="Product 3" className="dropdown-image" />
                    <span>Augumented Somatosensory Interactive Games</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bicycle} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Dynamic Bicycle</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={sandbox} alt="Product 3" className="dropdown-image" />
                    <span>Interactive Sandbox</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={immersive} alt="Product 3" className="dropdown-image" />
                    <span>Immersive Interactive Room</span>
                  </a>
                  <a href="#product3" className="dropdown-item">
                    <img src={bowling} alt="Product 3" className="dropdown-image" />
                    <span>Bowling Interactive Projection</span>
                  </a>

                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("industries")}>
                INDUSTRIES <span className={`triangle ${isDropdownOpen.industries ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.industries && (
                <div className="dropdown-menu full-width">
                  <a href="/Education" className="dropdown-item">
                    <img src={education} alt="Industry 1" className="dropdown-image" />
                    <span>Educational Games</span>
                  </a>
                  <a  href="/Events"  target='_blank' className="dropdown-item">
                    <img src={event} alt="Industry 2" className="dropdown-image" />
                    <span>Event Games</span>
                  </a>
                  <a href="/Fitness"  target='_blank' className="dropdown-item">
                    <img src={fitness} alt="Industry 3" className="dropdown-image" />
                    <span>Fitness & Sports Games</span>
                  </a>
                  <a href="/Family"  target='_blank' className="dropdown-item">
                    <img src={entertainment} alt="Industry 3" className="dropdown-image" />
                    <span>Family Entertainment Centers</span>
                  </a>
                  <a href="/Health"  target='_blank' className="dropdown-item">
                    <img src={health} alt="Industry 3" className="dropdown-image" />
                    <span>Healthcare Games</span>
                  </a>
                  <a href="/Location"  target='_blank' className="dropdown-item">
                    <img src={location} alt="Industry 3" className="dropdown-image" />
                    <span>Location-Based Entertainment</span>
                  </a>
                  <a href="/Museum"  target='_blank' className="dropdown-item">
                    <img src={museum} alt="Industry 3" className="dropdown-image" />
                    <span>Museums & Exhibits</span>
                  </a>
                  <a href="/Commerical"  target='_blank' className="dropdown-item">
                    <img src={commerical} alt="Industry 3" className="dropdown-image" />
                    <span>Commerical</span>
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => toggleDropdown("support")}>
                SUPPORT <span className={`triangle ${isDropdownOpen.support ? "rotate" : ""}`}>&#9660;</span>
              </button>
              {isDropdownOpen.support && (
                <div className="dropdown-menu full-width">
                  <a href="#support1" className="dropdown-item">
                    <img src={faq} alt="Support 1" className="dropdown-image" />
                    <span>FAQ</span>
                  </a>
                  <a href="#support2" className="dropdown-item">
                    <img src={live} alt="Support 2" className="dropdown-image" />
                    <span>Live Chat</span>
                  </a>
                  
                </div>
              )}
            </div>
            <button className="build-park-btn" onClick={openModal}>BUILD A PARK</button>
            {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
            <div className="social-icons1">
            <a target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='linkedin' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
          </div>
          
        </div>
      )}
    
    </>
{/* Navabar section ends  */}

{/* Why section starts  */}
    <div className="why">
        <div className="why-wrapper">
        <div className="head1">
          <h2>Why Choose Interactive Educational Games?</h2>
          <hr className="custom-line" />
          <p1>In today’s fast-paced world, education must go beyond traditional teaching methods. At InteractiveGames.io, we believe in revolutionizing the way children learn by blending education and play through immersive, interactive technology.</p1>
          <button className='unicode'  onClick={openModal}>Request a Quote &#8594; </button>
          </div>
        <div className="img1">
          <a href=""><img src={education1} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a>
        </div>
        </div>
        </div>
{/* why section ends  */}
{/* pop up contact form section starts */}
      {isModalOpen && (
        <div id='modal' className="modal" onClick={handleClickOutside} onSubmit={sendEmail}>
          <div className="modal-content">
            <span className='close' onClick={closeModal}>&times;</span>
            <form id='contactForm'>
              <label htmlFor='name'>Name:</label>
              <input type="text" id='name' name='name' required  value={formData.name} 
          onChange={handleChange} />

              <label htmlFor='email'>Email:</label>
              <input type="email" id='email' name='email' required  value={formData.email} 
          onChange={handleChange} />

              <label htmlFor='phone'>Phone Number:</label>
              <input type="tel" id='mobile' name='mobile' required value={formData.mobile} 
          onChange={handleChange}  />

              <button type='submitt'>Submit</button>
            </form>
            {responseMessage && <p className="success-message">{responseMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}
{/* pop up contact form section ends */}        

{/* positive section starts  */}
        <div class="positive">
    <div class="positive-wrapper">
        <div class="head2">
            <h2>The Positive Effects of Interactive Games on Children</h2>
        </div>
        <div class="pos-item">
            <div class="pos-item1">
                <img src={boost} alt="Boost Motor Skills,Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                <div class="pos-content">
                    <h4>Boost Motor Skills</h4>
                    <p>Interactive Wall Ball Games encourage hand-eye coordination, while Floor Games inspire full-body movement, developing fine and gross motor skills.</p>
                </div>
            </div>

            <div class="pos-item1">
                <img src={enhance} alt="Enhance Cognitive Abilities,Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                <div class="pos-content">
                    <h4>Enhance Cognitive Abilities</h4>
                    <p>Through problem-solving games and memory challenges, children exercise their critical thinking skills and cognitive development.</p>
                </div>
            </div>

            <div class="pos-item1">
                <img src={foster} alt="Foster Social Interaction,Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                <div class="pos-content">
                    <h4>Foster Social Interaction</h4>
                    <p>Our games encourage teamwork and communication, helping children develop essential social skills for emotional growth.</p>
                </div>
            </div>

            <div class="pos-item1">
                <img src={spark} alt="Spark Creativity,Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                <div class="pos-content">
                    <h4>Spark Creativity and Imagination</h4>
                    <p>Through themes such as space and fairy tales, children immerse themselves in imaginative worlds, boosting creativity while learning.</p>
                </div>
            </div>

            <div class="pos-item1">
                <img src={promote} alt="Promote Active Learning,Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                <div class="pos-content">
                    <h4>Promote Active Learning</h4>
                    <p>Interactive play promotes movement, making learning a fun, healthy, and active experience, reducing screen time.</p>
                </div>
            </div>
        </div>
    </div>
</div>
{/* positive section ends  */}

{/* sub section starts */}
            <div className="sub">
            <div className="sub-item">
                <div className="sub-item1">
                    <img src={learn} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                    <h3>Active Learning </h3>                   
                    <p1>Children learn best when they are actively engaged. Our interactive games allow children to physically participate in lessons, enhancing retention and understanding through movement and play.</p1>
                </div>
                <div className="sub-item2">
                   <img src={holistic} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                    <h3>Holistic Development </h3> 
                    <p1> Our games are designed to promote cognitive, emotional, and physical development. From problem-solving to hand-eye coordination, children benefit from a well-rounded learning experience.</p1>
                </div>
                <div className="sub-item3">
                    <img src={experience} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                    <h3>Customized Learning Experiences</h3> 
                    <p1>Every child learns at their own pace. Our technology offers customizable game themes, allowing educators to tailor content based on their curriculum, whether it’s letters, numbers, or interactive storytelling.</p1>
                </div>
                <div className="sub-item4">
                    <img src={engage} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                    <h3>Enhanced Engagement </h3> 
                    <p1>Say goodbye to passive learning! Interactive games grab children’s attention, making lessons more exciting and immersive. This increased engagement boosts motivation and concentration.</p1>
                </div>
                <div className="sub-item5">
                    <img src={stimulation} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" />
                    <h3>Sensory Stimulation </h3> 
                    <p1>By combining visual, auditory, and kinesthetic elements, our games stimulate multiple senses at once, helping children better grasp concepts and remember information.</p1>
                </div>
            </div>
            </div>
{/* sub section ends */}
   
{/* kinder section starts  */}
      <div className="kinder">
        <div className="kindergartens">
        <div className="fit">
            <p1>Our Interactive games fit seamlessly into any educational environment</p1>
            <a href=""><img src={eduenvpic} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a>
          </div>

          <div className="kinder-item">
            <div className="kinder-item1">
              <a href=""><img src={eduenvpic1} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a>
              <p1>Kindergartens</p1>
            </div>

            <div className="kinder-item1">
              <a href=""><img src={eduenvpic2} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a> 
              <p1>Playschools</p1>
            </div>

            <div className="kinder-item1">
              <a href=""><img src={eduenvpic3} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a>
              <p1>Early Learning Centers</p1>
            </div>  
          </div>
         
        </div>
      </div>
{/* Kindergarten section ends  */}

{/* feature section starts  */}
      <div className="featu">
      <div className="feature">
        <div className="features">
          <p1>Features of Our Educational Games</p1>
        </div>

        <div className="features-item">
          <div className="features-item1">
            <a href=""><img src={featimg1} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a>
            <h4>Customizable Content</h4>
            <p1>Tailor the game themes to fit your school's curriculum and learning objectives.</p1>
          </div>

          <div className="features-item2">
          <a href=""><img src={featimg2} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a>
            <h4>Plug-and-Play Installation</h4>
            <p1>Easy setup and minimal maintenance with durable,high-quality hardware.</p1>
          </div>

          <div className="features-item3">
            <a href=""><img src={featimg3} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a>
            <h4>Age-Appropriate Games</h4>
            <p1>Designed for children aged 3-8.ensuring content is both fun and educational fro specific age group.</p1>
          </div>

          <div className="features-item4">
            <a href=""><img src={featimg4} alt="Interactive educational games,AR games for schools,Educational wall games,Learning through play,Gamified learning solutions,Body tracking educational games,Educational games for preschools,Immersive learning experiences,Interactive floor games for education,Customized educational game content,educational games kindergarten,learning games for 3 year olds online free,free fun learning games for kindergarten,online reading games for elementary students,play based learning in early childhood education" /></a>
            <h4>Safe and Hygenic Play</h4>
            <p1>Non-contact technology ensures a safe,clean, and germ-free environment for your students.</p1>
          </div>
        </div>
      </div>
      </div>
{/* feature section ends  */}

{/* educontact section starts  */}
      <div className="educon">
        <div className="educontact">
          <div className="educonhead">
            <h3>Schedule a demo with us today</h3>
          </div>
          <div className="educontactform">
            <p1>Contact Us Now</p1>
            <form onSubmit={sendEmail}>
              <input type="text" name='name' placeholder='Name' required   value={formData.name} 
          onChange={handleChange}/>
              <input type="tel" name='mobile' placeholder='Mobile Number' required  value={formData.mobile} 
          onChange={handleChange}/>
              <input type="email" name='email' placeholder='Email' required value={formData.email} 
          onChange={handleChange}/>
              <button className='edusub' >Submit</button>
            </form>
            {responseMessage && <p className="success-messagee">{responseMessage}</p>}
            {errorMessage && <p className="error-messagee">{errorMessage}</p>}
          </div>
        </div>
      </div>
{/* educontact section ends  */}

          {/* Footer section starts  */}
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3 className='game'>INTERACTIVE GAMES</h3>
          <p className="powered-by">POWERED BY ESHARK</p>
          <p>
            At Eshark, we transform events with innovative gaming experiences,
            from interactive walls and floors to AR and body-tracking games.
            Serving schools, IT parks, and event managers, we deliver tailored
            solutions that engage, energize, and create unforgettable moments.
          </p>
          
          <div className="social-icons">
            <a  target='_blank' href="https://www.instagram.com/eshark_in/"><img className='insta' src={insta} alt='insta,instagram,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://www.facebook.com/Esharkdigital"><img className='facebook' src={facebook} alt='facebook,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://in.linkedin.com/company/eshark"><img className='linkedin' src={linkedin} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
            <a  target='_blank' href="https://wa.link/4r1o7b"><img className='whatsapp' src={whatsapp} alt='linkedin,interactive software,best game developmet software,game developmet company,video game development,game creation software,make my own game,interactive play systems,game design company,gaming software development companies,interactive game software,interactive software company,interactive entertainment,interactive business games,interactive game company,interactive 2,interactive game design,interactive website games,make interactive game,create interactive game,interactive game development,interactive entertainment companies,interactive entertainment games,game development equipment,interactive magic,technologies used in game development,sandbox create games,make a sandbox game,sandbox make games' /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>PRODUCTS</h3> 
          <ul>
            <a href=""><li>Interactive Floor</li></a>
            <a href=""><li>Interactive Wall</li></a>
            <a href=""><li>Augmented Somatosensory Games</li></a>
            <a href=""><li>Interactive Dynamic Bicycle</li></a>
            <a href=""><li>Interactive Sandbox</li></a>
            <a href=""><li>Immersive Interactive Room</li></a>
            <a href=""><li>Bowling Interactive Projection</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>INDUSTRIES</h3>
          <ul>
            <a href="/Education" target='_blank'><li>Educational Games</li></a>
            <a href=""><li>Event Games</li></a>
            <a href=""><li>Fitness & Sports Games</li></a>
            <a href=""><li>Family Entertainment Centers</li></a>
            <a href=""><li>Healthcare Games</li></a>
            <a href=""><li>Location-Based Entertainment</li></a>
            <a href=""><li>Museums & Exhibits</li></a>
            <a href=""><li>Commercial Games</li></a>
          </ul>
        </div>

        <div className="footer-column">
          <h3>SUPPORT</h3>
          <ul>
            <a href=""><li>FAQ</li></a>
            <a href=""><li>Live chat</li></a>
          </ul>
          <h3 className='Us'> Contact Us</h3>
          <p className='num'> <span> <img className='phone' src={phone}></img> </span> +91 90256 83379, +91 7010284155</p>
          <p> <span> <img className='mail' src={mail}></img> </span> <a href="mailto:Sales@eshark.in" className='sales'>Sales@eshark.in</a></p>
        </div>
      </div>
    </footer>
        {/* Footer section ends  */}

    </div>
    
  )
}

export default Education
