// import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import  Home  from './Components/Home/Home';
import Education  from './Components/Education/Education';
import Landing from './Components/Landing/Landing';
import Events from './Components/Events/Events';
import Family from './Components/Family/Family';
import Fitness from './Components/Fitness/Fitness';
import Health from './Components/Health/Health';
import Location from './Components/Location/Location';
import Museum from './Components/Museum/Museum';
import Commerical from './Components/Commerical/Commerical';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Landing_Page" element={<Landing />} />
        <Route path="/Education" element={<Education />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/Family" element={<Family />} />
        <Route path="/Fitness" element={<Fitness />} />
        <Route path="/Health" element={<Health />} />
        <Route path="/Location" element={<Location />} />
        <Route path="/Museum" element={<Museum />} />
        <Route path="/Commerical" element={<Commerical />} />
        
      </Routes>
    </Router>
  );
}

export default App;
